// cart Vuex module
import * as orderProcessor from '../utils/orderProcessor'
import { halfNHalfItem } from '../utils/const'
import * as cartHelper from '../utils/cartHelper'

const state = () => ({
  items: [],
  currentItem: null,
  configItemPrice: { // for easy use inside of the components, avoids rendering issue
    price: 0,
    extraPrice: 0
  },
  total: 0,
  activeChild: [], // array that we use to track what child element from currentItem the user customizing, empty array if we do not customize any child element
  requestedTime: null,
  type: null, // Logistic type (Delivery or collection)
  paymentMethod: null,
  serviceCharge: 0,
  totalMaxLimit: null,
  showRatingModal: false,
  directDiscount: 0,
  directDiscountType: '',
  directDiscountReason: '',
  editMode: false,
  comment: '',
  notes_for_delivery: '',
  notes_for_staff: '',
  notes_for_chef: '',
  newDeliveryCharge: null,
  fixedDealItems: [],
  marketing: true
})

const getters = {
  comment (state) {
    return state.comment
  },
  notesForDelivery (state) {
    return state.notes_for_delivery
  },
  notesForStaff (state) {
    return state.notes_for_staff
  },
  notesForChef (state) {
    return state.notes_for_chef
  },
  newDeliveryCharge (state) {
    return state.newDeliveryCharge
  },
  deliveryCharge (state, getters) {
    let charge = 0
    if (getters.type === 'delivery') {
      if (getters.currentStore) {
        charge = parseFloat(getters.currentStore.delivery_margin)
      }
      if (getters.addressSelected) {
        if (getters.total < parseFloat(getters.addressSelected.minimum_order)) {
          charge = charge + parseFloat(getters.addressSelected.extra_charge)
        } else {
          charge = charge + parseFloat(getters.addressSelected.charge) + parseFloat(getters.addressSelected.extra_charge)
        }
      }
    }
    return charge
  },
  totalMaxLimit (state) {
    return state.totalMaxLimit
  },
  editMode (state) {
    return state.editMode
  },
  directDiscountType (state) {
    return state.directDiscountType
  },
  directDiscount (state) {
    return state.directDiscount
  },
  directDiscountAmount (state, getters) {
    let discount = 0
    if (getters.directDiscountType === 'percent' && getters.directDiscount <= 100) {
      discount = (getters.directDiscount * state.total) / 100
    }
    if (getters.directDiscountType === 'cash') {
      discount = getters.directDiscount
    }
    return parseFloat(discount.toFixed(2))
  },
  directDiscountReason (state) {
    return state.directDiscountReason
  },
  showRatingModal (state) {
    return state.showRatingModal
  },
  cart (state) {
    return state.items
  },
  currentItem (state) {
    return state.currentItem
  },
  total (state) {
    return state.total ? parseFloat(state.total.toFixed(2)) : 0
  },
  totalItems (state) {
    return state.items.length
  },
  configItemPrice (state) {
    return state.configItemPrice
  },
  configItemTotalPrice (state, getters) {
    return (parseFloat(getters.configItemPrice.price) + parseFloat(getters.configItemPrice.extraPrice)).toFixed(2)
  },
  activeChild (state) {
    return state.activeChild
  },
  activeProduct (state, getters) { // could be a child of a product or the root product
    if (getters.activeChild.length === 0) {
      return state.currentItem
    }
    let item = getters.currentItem
    if (item.products && item.products.length) {
      for (let i = 0; i < getters.activeChild.length; i++) { // iterate over the products until we get the one that we are editing
        if (i === getters.activeChild.length - 1) {
          return item.products[getters.activeChild[i]] ?? item // get the active product, always the last item in the tracker array
        } else {
          item = item.products[getters.activeChild[i]] // jump to the next layer
        }
      }
    }
    return false
  },
  parentProduct (state, getters) {
    if (getters.activeChild.length === 1) {
      return state.currentItem
    }

    if (getters.activeChild.length > 1) {
      let products = getters.currentItem.products
      if (products.length) {
        for (let i = 0; i < getters.activeChild.length; i++) { // iterate over the products until we get the one that we are editing
          if (i === getters.activeChild.length - 2) {
            return products[getters.activeChild[i]] // get the active product, always the last item in the tracker array
          } else {
            products = products[getters.activeChild[i]].products // jump to the next layer
          }
        }
      }
    }
    return false
  },
  requestedTime (state) {
    return state.requestedTime
  },
  type (state) {
    return state.type ? state.type.toLowerCase() : ''
  },
  paymentMethod (state) {
    return state.paymentMethod
  },
  grandTotal (state, getters) {
    return (getters.grandTotalBeforeDiscount - getters.totalDiscount - getters.directDiscountAmount).toFixed(2)     //  make sure you have discount value
  },
  grandTotalBeforeDiscount (state, getters) {
    let total = state.total + getters.serviceCharge
    if (getters.newDeliveryCharge !== null) {
      total = total + getters.newDeliveryCharge
    } else {
      total = total + getters.deliveryCharge
    }
    return total.toFixed(2)
  },
  freePizzaRewardDealCount (state, getters) {
    if (getters.cart.length && getters.freePizzaRewardDeal) {
      const deals = getters.cart.filter(item => item.id === getters.freePizzaRewardDeal.id)
      if (deals) {
        let count = 0
        deals.forEach((deal) => {
          count += deal.qty
        })
        return count
      }
    }
    return 0
  },
  serviceCharge (state, getters) {
    if (typeof $nuxt !== 'undefined' && $nuxt.$platform === 'pos') {
      if (getters.currentOrder) {
        return parseFloat(getters.currentOrder.service_charge)
      }
      return 0
    }
    return parseFloat(state.serviceCharge)
  },
  fixedDealItems (state) {
    return state.fixedDealItems
  },
  marketingOptIn: state => state.marketing
}

const mutations = {
  setNewDeliveryCharge (state, data) {
    state.newDeliveryCharge = data
  },
  setComment (state, data) {
    state.comment = data
  },
  setNotesForDelivery (state, data) {
    state.notes_for_delivery = data
  },
  setNotesForStaff (state, data) {
    state.notes_for_staff = data
  },
  setNotesForChef (state, data) {
    state.notes_for_chef = data
  },
  setEditMode (state, data) {
    state.editMode = data
  },
  setDirectDiscountType (state, data) {
    state.directDiscountType = data
  },
  setDirectDiscount (state, data) {
    state.directDiscount = parseFloat(parseFloat(data).toFixed(2))
  },
  setDirectDiscountReason (state, data) {
    state.directDiscountReason = data
  },
  setActiveChild (state, data) {
    state.activeChild = data
  },
  setShowRatingModal (state, showRatingModal) {
    state.showRatingModal = showRatingModal
  },
  setCart (state, cart) {
    if (Array.isArray(cart)) {
      state.items = cart
    }
  },
  addItemToCart (state, item) {
    // Used for POS only
    if (item.cartIndex !== null && item.cartIndex !== undefined) {
      state.items.splice(item.cartIndex, 1, item)
    } else {
      state.items.push(item)
    }
  },
  addToCart (state, item) {
    state.currentItem = null
    if (item.cartIndex !== null && item.cartIndex !== undefined) {
      state.items.splice(item.cartIndex, 1, item)
      state.items[item.cartIndex].cartIndex = null
    } else {
      state.items.push(item)
    }
    state.activeChild = []
  },
  removeFromCart (state, cartIndex) {
    state.items.splice(cartIndex, 1)
  },
  setCurrentItem (state, item) {
    state.currentItem = item
  },
  increaseQty (state, idx) {
    state.items[idx].qty = state.items[idx].qty + 1
    if(state.items[idx].free === true){
      state.items[idx].discount = state.items[idx].discountPerQty * state.items[idx].qty
    }
  },
  decreaseQty (state, idx) {
    if (state.items[idx].qty > 1) {
      state.items[idx].qty = state.items[idx].qty - 1
      if(state.items[idx].free === true){
        state.items[idx].discount = state.items[idx].discountPerQty * state.items[idx].qty
      }
    }
  },
  clearCurrentItem (state) {
    state.currentItem = null
    state.configItemPrice = {
      price: 0,
      extraPrice: 0
    }
    state.activeChild = []
  },
  setConfigItemPrice (state, price) {
    state.configItemPrice = price
  },
  initCurrentItemProducts (state, items) { // initialize the root product with child products
    state.currentItem.products = items
  },
  setCustomizedActiveChild (state, idx) {
    state.activeChild.push(idx)
  },
  removeActiveChild (state) { // called when done editing the product
    state.activeChild.pop()
  },
  setCartTotal (state, total) {
    state.total = total
  },
  clearCart (state) {
    state.items = []
    state.total = 0
    state.requestedTime = null
    state.type = null
    state.paymentMethod = null
  },
  clearCartOnly (state) {
    state.items = []
    state.total = 0
  },
  setRequestedTime (state, time) {
    state.requestedTime = time
  },
  setType (state, logisticType) {
    state.type = logisticType ? logisticType.toLowerCase() : null
  },
  setPaymentMethod (state, paymentMethod) {
    state.paymentMethod = paymentMethod
  },
  setServiceCharge (state, serviceCharge) {
    state.serviceCharge = serviceCharge
  },
  removeCartDiscount (state) {
    state.items = state.items.filter(item => !item.free).map((item) => {
      if (item.hasOwnProperty('discount')) {
        // Below code for deleting property is because of reactivity issue
        const itemWithoutDiscount = { ...item }
        delete itemWithoutDiscount.discount
        item = itemWithoutDiscount
      }
      return item
    })
  },
  setTotalMaxLimit (state, totalMaxLimit) {
    state.totalMaxLimit = totalMaxLimit
  },
  removeFreePizzaInCart (state, freePizzaRewardDeal) {
    if (freePizzaRewardDeal) {
      state.items = state.items.filter(item => item.id !== freePizzaRewardDeal.id)
    }
  },
  setFixedDealItems (state, data) {
    state.fixedDealItems = data
  },
  setMarketing (state, data) {
    state.marketing = data
    console.log('set marketing', data)
  }
}

const actions = {
  removeCartItems (context, items) {
    // This removes multiple items together from the cart.
    const removedItemIndexes = []
    items.forEach(item => removedItemIndexes.push(item.cartIndex))
    const newCart = context.state.items.filter((item, index) => !removedItemIndexes.includes(index))
    context.commit('setCart', newCart)
    const result = orderProcessor.calculateCartTotal(context.getters.cart, context.getters.ingredientGroups)
    context.commit('setCartTotal', result.total)
  },
  clearDirectDiscount (context) {
    context.commit('setDirectDiscount', 0)
    context.commit('setDirectDiscountType', null)
    context.commit('setDirectDiscountReason', null)
    return true
  },
  removeItem (context, data) {
    const result = cartHelper.removeItem(context.state.items, data)
    context.commit('setCart', result.cart)
    const result2 = orderProcessor.calculateCartTotal(context.getters.cart, context.getters.ingredientGroups)
    context.commit('setCartTotal', result2.total)
    return result.removedChild
  },
  resetCurrentItem (context, data) {
    context.commit('setCurrentItem', orderProcessor.calculateItemTotal(data, context.rootGetters.ingredientGroups))
    context.commit('setConfigItemPrice', {
      price: context.rootGetters.currentItem.price.toFixed(2),
      extraPrice: context.rootGetters.currentItem.extraPrice.toFixed(2)
    })
  },
  sendOrder (context, data = {}) {
    if (this.$platform !== 'pos') {
      data.marketing = context.getters.marketingOptIn ? '1' : '0'
    }
    const orderData = Object.assign(data, {
      id: context.getters.currentUser ? context.getters.currentUser.id : null,
      cart: orderProcessor.sanitizeCart(JSON.parse(JSON.stringify(context.getters.cart))),
      requested_at: context.getters.requestedTime,
      order_type: context.getters.type,
      address: context.getters.type === 'delivery' ? context.getters.addressSelected.id : null,
      store_id: context.getters.currentStore.id,
      payment_method: context.getters.paymentMethod,
      voucher_id: context.getters.voucher ? context.getters.voucher.id : null,
      user_id: context.getters.currentUser ? context.getters.currentUser.id : null,
      comment: context.getters.comment
    })

    if (this.$platform === 'pos') {
      orderData.order_id = context.getters.currentOrder ? context.getters.currentOrder.id : null
      if (context.getters.newDeliveryCharge !== null && context.getters.newDeliveryCharge !== context.getters.deliveryCharge) {
        orderData.deliveryCharge = context.getters.newDeliveryCharge
      }
      if (context.getters.type === 'delivery') {
        orderData.notesForDelivery = context.getters.notesForDelivery
      }
      Object.assign(orderData, {
        notesForStaff: context.getters.notesForStaff,
        notesForChef: context.getters.notesForChef
      })
    }

    return this.$api.sendOrder(orderData).then(resp => {
      if (resp.data.data.id) {
        context.commit('clearCart')
        context.commit('removeVoucher')
        context.commit('setComment', null)
        context.commit('setNotesForDelivery', null)
        context.commit('setNotesForStaff', null)
        context.commit('setNotesForChef', null)
        return resp.data
      } if (resp.data.data.acsUrl) { // 3dSecure challenge
        return resp.data
      }
      return resp.data.message
    }).catch((e) => {
      if (e.response.status === 401) {
        if (this.$platform === 'pos') {
          this.$router.push('/login')
        } else {
          context.dispatch('logout')
        }
      }
      return e.response.data
    })
  },
  addToCart (context, item) {
    if (this.$platform === 'pos') {
      context.commit('addItemToCart', item)
    } else {
      context.commit('addToCart', item)
      context.commit('clearCurrentItem')
    }
    const result = orderProcessor.calculateCartTotal(context.getters.cart, context.getters.ingredientGroups)
    context.commit('setCartTotal', result.total)
    return context.getters.totalItems - 1
  },
  removeFromCart (context, idx) {
    context.commit('removeFromCart', idx)
    const result = orderProcessor.calculateCartTotal(context.getters.cart, context.getters.ingredientGroups)
    context.commit('setCartTotal', result.total)
  },
  setEditCurrentItem (context, data) {
    data.editMode = true
    context.dispatch('setCurrentItem', data)
  },
  setCurrentItem (context, data) { // set the currently configured product
    context.commit('setEditMode', data.editMode ? data.editMode : false)
    const activeChild = (data.idx && Array.isArray(data.idx)) ? data.idx : context.getters.activeChild // use a custom tracking array or the current tracking array
    let product

    if (data.product && context.getters.currentItem === null) { // initialize the product
      product = data.product
    }

    if (activeChild.length) { // search for the currently active child as the user edits one child product
      const setItem = (item) => { // use recursive function for object traversing
        if (Array.isArray(item.products)) {
          for (let j = 0; j < item.products.length; j++) {
            if (item === context.getters.activeProduct && (j === activeChild[activeChild.length - 1])) {
              if (data.product) {
                item.products[j] = JSON.parse(JSON.stringify(data.product)) // set the product
              }
            }

            if (item.products[j]) {
              item.products[j] = setItem(item.products[j]) // iterate also over the nested products
            }
          }
        }

        if (item === context.getters.activeProduct && data.product === undefined ) {
          item = Object.assign(item, data) // push incoming data
        }
        return item
      }
      product = setItem(context.rootGetters.currentItem)
    }

    if (data.product === undefined && activeChild.length === 0) { // this is the root item, assign the data that comes from actions
      product = Object.assign({}, context.getters.currentItem, data)

      if (data.selectedSize) { // traverse the child products to set the new size as the parent product
        const setSize = (item) => {
          if (item && Array.isArray(item.products)) {
            for (let j = 0; j < item.products.length; j++) {
              if (item.products[j] && item.products[j].sizes && Array.isArray(item.products[j].sizes)) {
                const size = item.products[j].sizes.filter(size => size.size === data.selectedSize.size)[0]
                if (size) {
                  item.products[j].selectedSize = size
                } else if (['pizza', 'halfHhalf'].includes(item.products[j].product_type)) {
                  item.products[j] = null
                }
              }
              if (item.products[j] && Array.isArray(item.products[j].products)) {
                item.products[j] = setSize(item.products[j])
              }
            }
          }
          if (item.sizes && item.selectedSize) {
            const size = item.sizes.filter(size => size.size === data.selectedSize.size)[0]
            if (size) {
              item.selectedSize = size
            } else {
              return null
            }
          }
          return item
        }
        product = setSize(product)
      }
    }
    context.commit('setCurrentItem', orderProcessor.calculateItemTotal(product, context.rootGetters.ingredientGroups))
    context.commit('setConfigItemPrice', {
      price: context.rootGetters.currentItem.price.toFixed(2),
      extraPrice: context.rootGetters.currentItem.extraPrice.toFixed(2)
    })
  },
  setSize (context, size = '') {
    if (typeof size === 'object' && Array.isArray(size.sizes)) {
      context.dispatch('setCurrentItem', { selectedSize: size.sizes.filter(s => s.size === size.size)[0] })
    } else {
      context.dispatch('setCurrentItem', { selectedSize: context.getters.currentItem.sizes.find(s => s.size === size) })
    }
  },
  setIngredientSelection (context, selection) {
    const predefined = context.getters.activeProduct.items.map(item => parseInt(item.id))
    const ingredientSelection = {}
    Object.keys(selection).forEach(key => { // filter false values
      if (selection[key] !== false) {
          ingredientSelection[key] = selection[key] // set only non predefined
      } else if (predefined.includes(parseInt(key))) {
        ingredientSelection[key] = -1 // ingredient was removed so we need to mark it
      }
    })

    context.dispatch('setCurrentItem', { ingredientSelection })
  },
  setBase (context, base) {
    context.dispatch('setCurrentItem', { selectedBase: base })
  },
  increaseQty (context, idx) {
    context.commit('increaseQty', idx)
    const result = orderProcessor.calculateCartTotal(context.getters.cart, context.getters.ingredientGroups)
    context.commit('setCartTotal', result.total)
  },
  decreaseQty (context, idx) {
    context.commit('decreaseQty', idx)
    const result = orderProcessor.calculateCartTotal(context.getters.cart, context.getters.ingredientGroups)
    context.commit('setCartTotal', result.total)
  },
  checkCartContent (context) {
    const products = JSON.parse(JSON.stringify(context.getters.products))
    if (context.getters.freePizzaRewardDeal) {
      products.deal.items.push(context.getters.freePizzaRewardDeal)
    }
    const schedule = context.getters.storeInfo ? context.getters.storeInfo.schedule : null
    const { cart, removedItems, removedItemsInDetail } = orderProcessor.checkCartContent(context.getters.cart, products, context.getters.timezone, schedule, context.getters.requestedTime)
    context.commit('setCart', cart)
    const { total } = orderProcessor.calculateCartTotal(context.getters.cart, context.getters.ingredientGroups)
    context.commit('setCartTotal', total)
    return { removedItems, removedItemsInDetail }
  },
  calculateFreeProductsQtyPerVocherType (context, voucherType) {
    let validItemQty = 0  // The total item qty in cart on which voucher is applied
    if(voucherType == 'deals'){
      getters.cart.forEach(cartItem => {
        if (!cartItem.free && context.getters.voucher.settings.deals.includes(cartItem.id)
        && (context.getters.voucher.settings.sizes.includes('any') || context.getters.voucher.settings.sizes.includes(cartItem.selectedSize.size))) {
          validItemQty += cartItem.qty
        }
      })
    } else if(voucherType == 'products'){
      let byType = false
      if (voucher.settings.product_type) {
        voucher.settings.product_type = voucher.settings.product_type.map(product_type_id => parseInt(product_type_id))
        byType = true
      } else {
        voucher.settings.products = voucher.settings.products.map(productId => parseInt(productId))
      }
      cart.forEach(cartItem => {
        if (!cartItem.free) {
          if (byType) {
            if (voucher.settings.product_type.includes(cartItem.product_type_id)
            && (voucher.settings.sizes.includes('any') || voucher.settings.sizes.includes(cartItem.selectedSize.size))) {
              validItemQty += cartItem.qty
            }
          } else {
            if (voucher.settings.products.includes(cartItem.id)
            && (voucher.settings.sizes.includes('any') || voucher.settings.sizes.includes(cartItem.selectedSize.size))) {
              validItemQty += cartItem.qty
            }
          }
        }
      })

    }
    let leftQty = parseInt(getters.voucher.settings.max_qty)
    leftQty = leftQty < validItemQty ? leftQty : validItemQty
    return leftQty
  },
  removeCurrentItemInCart (context) {
    context.commit('removeFromCart', context.getters.currentItem.cartIndex)
    context.dispatch('setCurrentItem', { cartIndex: null, selectedSize: null })
  },
  updateCurrentItemForHnh (context, data) {
    // Here we replace currentItem
    let item
    if (data.cartIndex !== null && data.cartIndex !== undefined) {
      // When hnh is the cart item.
      item = data
    } else {
      // When hnh is the item inside deal.
      item = Object.assign({}, context.getters.currentItem)
      item.products[context.getters.activeChild[0]] = data
    }
    context.commit('setCurrentItem', orderProcessor.calculateItemTotal(item, context.rootGetters.ingredientGroups))
    context.commit('setConfigItemPrice', {
      price: context.rootGetters.currentItem.price.toFixed(2),
      extraPrice: context.rootGetters.currentItem.extraPrice.toFixed(2)
    })
  },
  splitPizza (context) {
    // This prepares the hnh and initializes with first pizza.
    const firstHalf = Object.assign({}, context.getters.activeProduct)
    delete firstHalf.allergies
    const hnh = Object.assign({}, halfNHalfItem)
    hnh.sizes = firstHalf.sizes
    hnh.selectedSize = firstHalf.selectedSize
    hnh.selectedBase = firstHalf.selectedBase
    delete firstHalf.selectedBase
    hnh.products[0] = firstHalf
    if (context.getters.currentItem.product_type === 'pizza') {
      hnh.cartIndex = context.getters.activeProduct.cartIndex
    }
    return hnh
  },
  getFixedDealItems (context, id) {
    return this.$api.fixDealItems(id).then((resp) => {
      context.commit('setFixedDealItems', resp.data)
      return resp.data
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
