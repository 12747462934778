import { render, staticRenderFns } from "./HeaderTop.vue?vue&type=template&id=0f70cda7&scoped=true"
import script from "./HeaderTop.vue?vue&type=script&lang=js"
export * from "./HeaderTop.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f70cda7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {StoreDetail: require('/home/uattopspizzaco/builds/fZHTZxRpf/0/standbyteam/topspizza.co.uk/frontend/web/components/StoreDetail.vue').default,StoreModal: require('/home/uattopspizzaco/builds/fZHTZxRpf/0/standbyteam/topspizza.co.uk/frontend/web/components/StoreModal.vue').default})
