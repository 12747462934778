import VuexPersist from 'vuex-persist'
import Cookies from 'js-cookie'
import cookie from 'cookie'

export default ({ store, req, isDev }, inject) => {
  new VuexPersist({
    modules: ['user', 'data'],
    restoreState: (key, storage) => {
      try {
        const cookieData = process.client
          ? Cookies.get(key) ? JSON.parse(Cookies.get(key)) : ''
          : (req ? cookie.parse(req.headers.cookie || '')[key] : '')
        if (cookieData) {
          inject('cookie', cookieData)
          inject('cookieKey', key)
        }
        return cookieData
      } catch (e) {
        console.log(e)
        return null
      }
    },
    saveState: (key, state, storage) => {
      Cookies.set(key, JSON.stringify(state), { expires: 365, secure: false })
    }
  }).plugin(store)
}
