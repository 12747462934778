// set footer info pages
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      staticPages: []
    }
  },
  computed: {
    ...mapGetters(['infoPages', 'currentUser']),
    tags () {
      const tags = []
      this.totalPages.forEach((infoPage) => {
        tags.push(...infoPage.tags)
      })
      return [...new Set(tags)]
    },
    totalPages () {
      return this.infoPages ? this.infoPages.concat(this.staticPages) : []
    },
    footer () {
      if (this.tags.length) {
        const pages = []
        this.tags.forEach((tag) => {
          const section = {
            type: tag,
            pages: []
          }
          this.totalPages.forEach((infoPage, key) => {
            if (infoPage.tags.includes(tag)) {
              section.pages.push(infoPage)
            }
          })
          pages.push(section)
        })
        return pages
      }
      return null
    }
  },
  watch: {
    currentUser (newValue) {
      this.updateStaticPages()
    }
  },
  methods: {
    updateStaticPages () {
      if(this.currentUser) {
        this.staticPages = [
          {
            title: 'List of stores',
            tags: ['About'],
            url: '/stores'
          },
          {
            title: 'Profile',
            tags: ['My account'],
            url: '/profile?tab=1'
          },
          {
            title: 'Log Out', //  The "Log Out" text is used statically on footer.vue so should not edit
            tags: ['My account']
          },
          {
            title: 'Mobile Apps',
            tags: ['About'],
            url: '/info/mobile-apps'
          }
        ]
      } else {
        this.staticPages = [
          {
            title: 'List of stores',
            tags: ['About'],
            url: '/stores'
          },
          {
            title: 'Sign in / Register',
            tags: ['My account'],
            url: '/auth'
          },
          {
            title: 'Mobile Apps',
            tags: ['About'],
            url: '/info/mobile-apps'
          }
        ]
      }
    }
  },
  mounted () {
    this.updateStaticPages()
  }
}
