//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapActions, mapGetters } from 'vuex'
import store from '../../common/mixins/store'

export default {
  name: 'StoreDetail',
  mixins: [store],
  props: {
    storeDetailShow: {
      type: Boolean,
      default () {
        return false
      }
    },
    showStoreChangeBtn: {
      type: Boolean,
      default () {
        return false
      }
    },
    showFooterInfo: {
      type: Boolean,
      default () {
        return false
      }
    },
    showDetailsBox: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      visible: false
    }
  },
  computed: {
    ...mapGetters(['currentStore', 'storeInfo']),
    mapPointer () {
      if (this.storeInfo && this.storeInfo.lat && this.storeInfo.lon) {
        return { lat: parseFloat(this.storeInfo.lat), lng: parseFloat(this.storeInfo.lon) }
      }
      return null
    }
  },
  watch: {
    storeDetailShow (newValue) {
      this.visible = newValue
    }
  },
  mounted () {
    if (this.storeDetailShow) {
      this.visible = true
    }
    if (this.currentStore) {
      this.setCurrentStoreData({ store_id: this.currentStore.id })
    }
  },
  methods: {
    ...mapActions(['getStores', 'setCurrentStore', 'setCurrentStoreData']),
    async removeCurrentStore () {
      this.$emit('update:storeDetailShow', false)
      await this.setCurrentStore(null)
      this.$bvModal.show('store-modal')
    }
  }
}
