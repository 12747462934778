// user Vuex module

const state = () => ({
  currentUser: null,
  token: null,
  phone: null
})

const getters = {
  phone (state) {
    return state.phone
  },
  currentUser (state) {
    return state.currentUser
  },
  availableLoyaltyPoints (state, getters) {
    let points = 0
    if (getters.currentUser) {
      points = getters.currentUser.loyalty_points ?? 0
      points = points < 0 ? 0 : points
      if (getters.cart.length && getters.freePizzaRewardDeal) {
        const loyaltyDeal = getters.cart.filter(item => item.id === getters.freePizzaRewardDeal.id)
        if (loyaltyDeal) {
          let count = 0
          loyaltyDeal.forEach(deal => {
            count += deal.qty
          })
          points -= count * 1000
        }
      }
    }
    return points
  }
}

const mutations = {
  setPhone (state, phone) {
    state.phone = phone
  },
  setUser (state, user) {
    state.currentUser = user
  },
  setToken (state, token) {
    state.token = token
  },
  setLoyalty (state, loyaltyPoints) {
    if (state.currentUser) {
      state.currentUser.loyalty_points = loyaltyPoints
    }
  }
}

const actions = {
  resetPosOrdering (context) {
    context.commit('setType', null)
    context.commit('setUser', null)
    context.commit('setAddresses', [])
    context.commit('setAddressSelected', null)
    context.commit('setPhone', null)
    context.commit('setCurrentOrder', null)
    context.commit('setDirectDiscountType', null)
    context.commit('setDirectDiscount', 0)
    context.commit('setDirectDiscountReason', null)
    context.commit('setNewDeliveryCharge', null)
  },
  findCustomer (context, data) {
    context.commit('setUser', null)
    context.commit('setAddresses', [])
    context.commit('setAddressSelected', null)
    return this.$api.findCustomer(data).then(resp => {
      context.commit('setUser', resp.data.data.user)
      context.commit('setAddresses', resp.data.data.addresses)
      const defaultAddress = resp.data.data.addresses.find(address => address.is_default)
      if (defaultAddress) {
        context.commit('setAddressSelected', defaultAddress)
      }
      return resp.data
    }).catch(e => {
      if (e.response === undefined) {
        return false
      }
      if (e.response.status === 401 && this.$platform === 'pos') {
        this.$router.push('/login')
      }
      return e.response.data
    })
  },
  getCustomer (context) {
    return this.$api.getCustomer({ id: context.getters.currentUser.id, platform: this.$platform }).then((resp) => {
      context.commit('setUser', resp.data.data)
      return resp.data
    }).catch((e) => {
      if (e.response === undefined) {
        return false
      }
      return e.response.data
    })
  },
  login (context, data) {
    return this.$api.login(data).then(resp => {
      context.commit('setUser', resp.data.data.user)
      context.commit('setToken', resp.data.data.token)
      return resp.data
    }).catch(e => {
      if (e.response === undefined) {
        return false
      }
      return e.response.data
    })
  },
  register (context, data) {
    return this.$api.register(data).then(resp => {
      return resp.data
    }).catch(e => e.response.data)
  },
  verifyRegister (context, data) {
    return this.$api.verifyRegister(data).then(resp => {
      // Expecting token and user details object in response on successful verification
      context.commit('setToken', resp.data.data.token)
      context.commit('setUser', resp.data.data.user)
      return resp.data
    }).catch(e => e.response.data)
  },
  updateCustomer (context, data) {
    return this.$api.updateCustomer(data).then(resp => {
      context.commit('setUser', resp.data.data)
      return resp.data
    }).catch(e => {
      if (e.response === undefined) {
        return false
      }
      if (e.response.status === 401) {
        if (this.$platform === 'pos') {
          // this.$router.push('/login')
        } else {
          context.dispatch('logout')
        }
      }
      return e.response.data
    })
  },
  createCustomer (context, data) {
    return this.$api.createCustomer(data).then(resp => {
      context.commit('setUser', resp.data.data.user)
      context.commit('setAddresses', resp.data.data.addresses)
      const defaultAddress = resp.data.data.addresses.find(address => address.is_default === '1')
      if (defaultAddress) {
        context.commit('setAddressSelected', defaultAddress)
      }
      return resp.data
    }).catch(e => e.response.data)
  },
  requestDelete (context) {
    return this.$api.requestDelete().then(resp => {
      context.commit('setUser', null)
      context.commit('setToken', null)
      return resp.data
    }).catch(e => e.response.data)
  },
  verifylink (context, data) {
    return this.$api.verifylink(data).then(resp => {
      return resp.data
    }).catch(e => e.response.data)
  },
  deleteAccount (context, data) {
    return this.$api.deleteAccount(data).then(resp => {
      return resp.data
    }).catch(e => e.response.data)
  },
  logout (context) {
    return this.$api.logout().then(resp => {
      context.commit('setUser', null)
      context.commit('setToken', null)
      context.commit('setAddresses', [])
      context.commit('setAddressSelected', null)
      return resp.data
    }).catch(e => {
      context.commit('setUser', null)
      context.commit('setToken', null)
      context.commit('setAddresses', [])
      context.commit('setAddressSelected', null)
      return e.response.data
    })
  },
  loginFacebook (context, data) {
    return this.$api.loginFacebook().then(resp => {
      return resp.data
    }).catch(e => e.response.data)
  },
  forgotPassword (context, data) {
    return this.$api.forgotPassword(data).then(resp => {
      return resp.data
    }).catch(e => e.response.data)
  },
  resetPassword (context, data) {
    return this.$api.resetPassword(data).then(resp => {
      return resp.data
    }).catch(e => e.response.data)
  },
  getLoyalty (context, data = null) {
    return this.$api.getLoyalty(data).then(resp => {
      if (resp.data.status === 'Success') {
        context.commit('setLoyalty', resp.data.data.loyalty_points)
        if (context.getters.availableLoyaltyPoints < 0) {
          context.commit('removeFreePizzaInCart', context.getters.freePizzaRewardDeal)
        }
      }
    }).catch(e => {
      if (e.response === undefined) {
        return false
      }
      if (e.response.status === 401) {
        context.dispatch('logout')
      }
      return e.response.data
    })
  },
  rateCustomer (context, data) {
    return this.$api.rateCustomer(data).then(resp => {
      return resp.data
    }).catch(e => e.response.data)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
