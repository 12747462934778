// persistent data (used also for ssr)
import timeHelper from '../utils/time'
import * as ingredientUtils from '../utils/ingredients'
import * as orderProcessor from '../utils/orderProcessor'

const state = () => ({
  currentStore: null,
  reorderTooltip: true
})

const getters = {
  currentStore (state) {
    return state.currentStore
  },
  storeYesterdayTodayTimeRange (state, getters) {
    if (getters.currentStore && getters.currentMoment) {
      return timeHelper.storeYesterdayTodayTimeRange(getters.storeInfo, getters.currentMoment)
    }
    return null
  },
  isStoreClosed (state, getters) {
    const range = getters.storeYesterdayTodayTimeRange
    if (getters.timezone && getters.currentTime && range) {
      const inYesterday = range.yesterday && getters.currentMoment.isBetween(range.yesterday.startTime, range.yesterday.endTime)
      const inToday = range.today && getters.currentMoment.isBetween(range.today.startTime, range.today.endTime)
      return !(inYesterday || inToday)
    }
    return false
  },
  reorderTooltip (state) {
    return state.reorderTooltip
  }
}

const mutations = {
  setStore (state, store) {
    state.currentStore = store
  },
  setReorderTooltip (state, reorderTooltip) {
    state.reorderTooltip = reorderTooltip
  }
}

const actions = {
  setCurrentStoreData (context, data) {
    this.$api.getStore({...data, platform: this.$platform}).then((resp) => {
      context.commit('setStore', resp.data.data.store)
    }).catch((e) => {
      console.log(e)
    })
  },
  setCurrentStore (context, data) {
    if (['web', 'app'].includes(this.$platform)) {
      context.commit('removeVoucher')
      context.commit('removeCartDiscount')
    }

    return this.$api.getStore({...data, platform: this.$platform}).then((resp) => {
      context.commit('setStore', resp.data.data.store)
      context.commit('setProducts', resp.data.data.products)
      context.commit('setStoreInfo', resp.data.data.storeInfo)
      context.commit('setServiceCharge', resp.data.data.settings.serviceCharge)
      context.commit('setFreePizzaRewardDeal', resp.data.data.freePizzaRewardDeal)

      if (resp.data.data.products.ingredient
        && resp.data.data.products.ingredient.items
        && Array.isArray(resp.data.data.products.ingredient.items)
        && resp.data.data.ingredientGroups
        && Array.isArray(resp.data.data.ingredientGroups)) {
        context.commit('setIngredientGroups',
          ingredientUtils.compileIngredientGroups(resp.data.data.products.ingredient.items,
            resp.data.data.ingredientGroups)) // return the groups with additional data
      }
      if (['web', 'app'].includes(this.$platform)) {
        context.commit('setBanners', resp.data.data.banners)
        context.commit('setTotalMaxLimit', resp.data.data.settings.totalMaxLimit)
        if (context.getters.currentStore) {
          const { total } = orderProcessor.calculateCartTotal(context.getters.cart, context.getters.ingredientGroups)
          context.commit('setCartTotal', total)
        } else {
          context.commit('clearCartOnly')
        }
      }
      return resp.data
    }).catch((e) => {
      return e.response.data
    })
  },
  unsetCurrentStore (context) {
    context.commit('setStore', null)
    context.commit('setProducts', {})
    context.commit('setBanners', [])
    context.commit('setStoreInfo', null)
    context.commit('setIngredientGroups', [])
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
