export function containsCookable (order) {
  if (order.cart) {
    if (order.cart.find(cartItem => ['pizza', 'halfNHalf', 'dip', 'side'].includes(cartItem.product_type))) {
      return true
    }
    const cookableDeal = order.cart.find((cartItem) => {
      if (cartItem.product_type === 'deal') {
        if (cartItem.products.find(product => ['pizza', 'halfNHalf', 'dip', 'side'].includes(product.product_type))) {
          return true
        }
      }
      return false
    })
    return cookableDeal ? true : false
  }
  return false
}
