// info Vuex module

const state = () => ({
  addresses: [],
  addressSelected: null

})

const getters = {
  addresses (state) {
    return state.addresses
  },
  addressSelected (state) {
    return state.addressSelected
  }
}

const mutations = {
  setAddresses (state, addresses) {
    state.addresses = addresses
  },
  setAddressSelected (state, addressSelected) {
    state.addressSelected = addressSelected
  }
}

const actions = {
  getCustomerAddresses (context, data) {
    return this.$api.getCustomerAddresses(data).then((resp) => {
      context.commit('setAddresses', resp.data.data)
      const defaultAddress = context.getters.addresses.find(address => address.is_default)
      if (defaultAddress) {
        context.commit('setAddressSelected', defaultAddress)
      }
      return resp.data
    }).catch((e) => {
      if (e.response.status === 401) {
        context.dispatch('logout')
      }
      return e.response.data
    })
  },
  getPostcodeAddresses (context, data) {
    return this.$api.getPostcodeAddresses(data).then((resp) => {
      return resp.data
    }).catch(e => e.response.data)
  },
  addUpdateAddress (context, data) {
    return this.$api.addUpdateAddress(data).then(async (resp) => {
      const addresses = resp.data.data
      context.commit('setAddresses', resp.data.data)
      const defaultAddress = addresses.find(address => address.is_default)
      if (defaultAddress) {
        context.dispatch('setAddressSelected', defaultAddress)
        if (this.$platform !== 'pos' && defaultAddress.store) {
          const result = await context.dispatch('setCurrentStore', { store_id: defaultAddress.store.id })
          if (result.status === 'Error') {
            context.commit('setStore', null)
            return result
          }
        }
      }
      return resp.data
    }).catch(e => e.response.data)
  },
  deleteAddress (context, data) {
    return this.$api.deleteAddress(data).then((resp) => {
      context.commit('setAddresses', resp.data.data)
      return resp.data
    }).catch(e => e.response.data)
  },
  setAddressSelected(context, data) {
    context.commit('setAddressSelected', data)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
