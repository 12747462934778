import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _71b111ec = () => interopDefault(import('../pages/apps/index.vue' /* webpackChunkName: "pages/apps/index" */))
const _5999ccbc = () => interopDefault(import('../pages/auth/index.vue' /* webpackChunkName: "pages/auth/index" */))
const _3f8e723c = () => interopDefault(import('../pages/changePassword.vue' /* webpackChunkName: "pages/changePassword" */))
const _ff21bd42 = () => interopDefault(import('../pages/loyalty.vue' /* webpackChunkName: "pages/loyalty" */))
const _40247d42 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _c67d88ae = () => interopDefault(import('../pages/stores.vue' /* webpackChunkName: "pages/stores" */))
const _a4df7978 = () => interopDefault(import('../pages/account/delete.vue' /* webpackChunkName: "pages/account/delete" */))
const _64e36e9c = () => interopDefault(import('../pages/auth/verifyRegister.vue' /* webpackChunkName: "pages/auth/verifyRegister" */))
const _ff5dd390 = () => interopDefault(import('../pages/order/cart.vue' /* webpackChunkName: "pages/order/cart" */))
const _4a2c255e = () => interopDefault(import('../pages/order/checkout.vue' /* webpackChunkName: "pages/order/checkout" */))
const _35f82d78 = () => interopDefault(import('../pages/order/logistic.vue' /* webpackChunkName: "pages/order/logistic" */))
const _7227291e = () => interopDefault(import('../pages/order/payment.vue' /* webpackChunkName: "pages/order/payment" */))
const _6192758d = () => interopDefault(import('../pages/order/thankyou.vue' /* webpackChunkName: "pages/order/thankyou" */))
const _3751e550 = () => interopDefault(import('../pages/order/tracker.vue' /* webpackChunkName: "pages/order/tracker" */))
const _51d655f4 = () => interopDefault(import('../pages/menu/product/_.vue' /* webpackChunkName: "pages/menu/product/_" */))
const _3d60bbc5 = () => interopDefault(import('../pages/menu/deal/_.vue' /* webpackChunkName: "pages/menu/deal/_" */))
const _0c981126 = () => interopDefault(import('../pages/menu/_.vue' /* webpackChunkName: "pages/menu/_" */))
const _15292d96 = () => interopDefault(import('../pages/info/_.vue' /* webpackChunkName: "pages/info/_" */))
const _572ecc2b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _306fc490 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/apps",
    component: _71b111ec,
    name: "apps"
  }, {
    path: "/auth",
    component: _5999ccbc,
    name: "auth"
  }, {
    path: "/changePassword",
    component: _3f8e723c,
    name: "changePassword"
  }, {
    path: "/loyalty",
    component: _ff21bd42,
    name: "loyalty"
  }, {
    path: "/profile",
    component: _40247d42,
    name: "profile"
  }, {
    path: "/stores",
    component: _c67d88ae,
    name: "stores"
  }, {
    path: "/account/delete",
    component: _a4df7978,
    name: "account-delete"
  }, {
    path: "/auth/verifyRegister",
    component: _64e36e9c,
    name: "auth-verifyRegister"
  }, {
    path: "/order/cart",
    component: _ff5dd390,
    name: "order-cart"
  }, {
    path: "/order/checkout",
    component: _4a2c255e,
    name: "order-checkout"
  }, {
    path: "/order/logistic",
    component: _35f82d78,
    name: "order-logistic"
  }, {
    path: "/order/payment",
    component: _7227291e,
    name: "order-payment"
  }, {
    path: "/order/thankyou",
    component: _6192758d,
    name: "order-thankyou"
  }, {
    path: "/order/tracker",
    component: _3751e550,
    name: "order-tracker"
  }, {
    path: "/menu/product/*",
    component: _51d655f4,
    name: "menu-product-all"
  }, {
    path: "/menu/deal/*",
    component: _3d60bbc5,
    name: "menu-deal-all"
  }, {
    path: "/menu/*",
    component: _0c981126,
    name: "menu-all"
  }, {
    path: "/info/*",
    component: _15292d96,
    name: "info-all"
  }, {
    path: "/",
    component: _572ecc2b,
    name: "index"
  }, {
    path: "/*",
    component: _306fc490,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
