export function removeItem (cart, toBeRemoved) {
  let removedChild = []
  let removed = false
  const getItem = (item) => {
    if (!item) {
      return null
    }
    if (item === toBeRemoved) {
      removed = true
      return null
    }
    if (item.products && Array.isArray(item.products)) {
      for (let j = 0; j < item.products.length; j++) {
        removedChild.push(j)
        item.products[j] = getItem(item.products[j])
        if (removed) {
          break
        }
        removedChild.pop()
      }
    }
    return item
  }

  if (cart.length) {
    for (let i = 0; i < cart.length; i++) {
      removedChild.push(i)
      cart[i] = getItem(cart[i])
      if (removed) {
        break
      }
      removedChild = []
    }
    cart = JSON.parse(JSON.stringify(cart.filter(item => item)))
  }
  return { cart, removedChild }
}

export function removeCartDiscount (cart) {
  cart = cart.filter(item => !item.free).map((item) => {
    if (item.hasOwnProperty('discount')) {
      delete item.discount
    }
    return item
  })
  return cart
}