import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters(['storeInfo']),
    schedule () {
      if (this.storeInfo) {
        return [
          { day: 'monday', time: this.storeInfo.schedule.monday },
          { day: 'tuesday', time: this.storeInfo.schedule.tuesday },
          { day: 'wednesday', time: this.storeInfo.schedule.wednesday },
          { day: 'thursday', time: this.storeInfo.schedule.thursday },
          { day: 'friday', time: this.storeInfo.schedule.friday },
          { day: 'saturday', time: this.storeInfo.schedule.saturday },
          { day: 'sunday', time: this.storeInfo.schedule.sunday }
        ]
      }
      return []
    }
  },
  methods: {
    ...mapActions(['setCurrentStore', 'checkCartContent']),
    setStore (store) {
      this.loading = true
      this.setCurrentStore({ store_id: store.id }).then(() => {
        this.redirectAfterStoreSet(store)
      }).finally(() => {
        this.loading = false
        this.checkCartContent()
      })
    }
  }
}
