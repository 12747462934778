// vuex store banners

const state = () => ({
  banners: []
})

const getters = {
  banners (state) {
    return state.banners
  }
}

const mutations = {
  setBanners (state, data) {
    if (Array.isArray(data)) {
      state.banners = data
    }
  }
}

export default {
  state,
  getters,
  mutations
}