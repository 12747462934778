//
//
//
//
//
//
//
//
//


import { mapGetters, mapActions } from 'vuex'
import Footer from '../components/footer/Footer.vue'
import Header from '../components/header/Header.vue'
import CartItemAddedModal from '~/components/cart/CartItemAddedModal'

export default {
  components: {
    Header,
    Footer,
    CartItemAddedModal
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  created () {
    if (process.client && this.currentUser) {
      this.getLoyalty()
    }
  },
  methods: {
    ...mapActions(['getLoyalty'])
  }
}
