/**
 *
 * Module for calculating and validating the cart content
 * ==========================================================
 * we use this for both server side and client
 *
 */
import slug from 'slug'
import { halfNHalfItem } from './const.js'
import * as ingredientUtils from './ingredients.js'
import { allSizes } from './const.js'
import moment from 'moment'
import 'moment-timezone'
import { checkDealSchedule } from './dealSchedule.js'

export function calculateItemTotal (product, ingredientGroups) { // calculate item prices
  if (product) {
    product.price = 0
    if (product.id === -1) {
      product.extraPrice = 1
    } else {
      product.extraPrice = 0
    }

    if (product.selectedSize) {
      product.price = parseFloat(product.selectedSize.price)
    }

    if (product.product_type === 'pizza') { // calculate extra cost for added ingredients
      if (product.ingredientSelection) {
        product = calculateIngredientsTotal(product, ingredientGroups)
      }
      if (product.selectedBase) {
        product = calculateBasePrice(product)
      }
    }

    if (product.product_type === 'deal') {
      if (product.products) {
        product.products = product.products.map(childProduct => {
          if (childProduct) {
            childProduct = calculateItemTotal(childProduct, ingredientGroups)
            product.extraPrice += childProduct.extraPrice
          }
          return childProduct
        })
      }
    }

    if (product.product_type === 'halfNHalf') {
      if (product.selectedBase) {
        product = calculateBasePrice(product)
      }

      if (product.products && product.selectedSize) {
        product.products = product.products.map(childProduct => {
          if (childProduct && childProduct.product_type !== 'ingredient') {
            childProduct = calculateItemTotal(childProduct, ingredientGroups)
            product.extraPrice += (childProduct.extraPrice / 2) // toppings have half price
          }
          return childProduct
        })
        const products = product.products.filter(childProduct => childProduct !== null)

        if (products.length) {
          if (products[1]) {
            const prices = products.filter(product => product.product_type === 'pizza')

            product.price = Math.max(parseFloat(prices[0].price), parseFloat(prices[1].price))
          } else {
            product.price = products[0].price
          }
        }
      }
    }

    if (product.free === true && product.discountPerQty) {  //  update discount from free products
      product.discountPerQty = product.price
      product.discount = product.discountPerQty * product.qty
    }
  }
  return product
} // calculate the total for a cart item

export function calculateCartTotal (cart, ingredientGroups = false, discount = 0) { // calculates the cart
  let total = 0
  let ignoreDiscount = false
  if (discount) {
    // Discount variable is already set so we don't need to calculate it (it's reusable voucher case)
    ignoreDiscount = true
  }
  cart = cart.map(product => {
    if (ingredientGroups) { // if we pass the ingredientGroups it recalculates the item
      product = calculateItemTotal(product, ingredientGroups)
    }
    total += (product.price + product.extraPrice) * product.qty
    if (product.discount && !ignoreDiscount) {
      discount += product.discount * product.qty
    }
    return product
  })

  if (total < discount) {
    discount = total
  }
  // console.log('cart total', { total, cart, discount })
  return { total, cart, discount: parseFloat(discount.toFixed(2)) }
}

export function calculateIngredientsTotal (product, ingredientGroups) { // ingredient calculation algorithm
  const sauceCheese = ingredientGroups.filter(group => ['sauce', 'cheese'].includes(group.slug)).map(group => group.ingredientIds).flat()
  const noIds = ingredientGroups.map(group => group.ingredients).flat().filter(ingredient => ingredient.slug.includes('no-')).map(ingredient => ingredient.id)
  const ingredients = getIngredientPricesForProduct(product, JSON.parse(JSON.stringify(ingredientGroups)))
  const selection = Object.entries(product.ingredientSelection).filter(item => item[1] !== false)
  const predefinedIds = product.items.map(item => item.id)

  const selectedIds = selection.sort((a, b)  => b[1] - a[1]).map(item => parseInt(item[0]))
  const removedIds = selection.filter(item => item[1] === -1).map(item => parseInt(item[0]))
  const noIngredientSelection = selectedIds.filter(id => noIds.includes(id))

  let extra = 0

  let free_toppings = 0

  if (product.free_toppings) {
    try {
      free_toppings = parseInt(product.free_toppings)
    } catch (e) {}
  }

  let freeSwaps = removedIds.length + free_toppings - noIngredientSelection.length

  const calculateGroup = (id) => {
    if (!predefinedIds.includes(id) && freeSwaps <= 0) { // check if single to double
      extra += ingredients[id].price * product.ingredientSelection[id]
      freeSwaps -= product.ingredientSelection[id]
    }

    if (predefinedIds.includes(id) && product.ingredientSelection[id] > 1) { // check if single to double on predefined
      if (freeSwaps <= 0) {
        extra += ingredients[id].price
      }
      freeSwaps--
    }

    if (!predefinedIds.includes(id) && freeSwaps >= 0) {
      if (product.ingredientSelection[id] > 1 && freeSwaps < 2) {
        extra += ingredients[id].price
      }

      freeSwaps = freeSwaps - product.ingredientSelection[id]
    }
  }

  selectedIds.filter(id => sauceCheese.includes(id)).forEach(calculateGroup)
  selectedIds.filter(id => !sauceCheese.includes(id)).forEach(calculateGroup)

  product.extraPrice = extra

  return product
}

export function calculateBasePrice (product) { // set the base price to extra
  try {
    if (product.selectedSize && product.selectedBase) {
      const price = product.selectedBase.sizes.filter(size => size.size === product.selectedSize.size)
      if (price.length) {
        product.extraPrice += parseFloat(price[0].price)
      }
    }
  } catch (e) {
    console.log(e)
  }
  return product
}

export function getIngredientPricesForProduct (product, ingredientGroups) { // find the price for an ingredient
  if (product.selectedSize && product.ingredientSelection) {
    return ingredientGroups.map((group) => {
      group.ingredientIds = group.ingredientIds.filter((id) => Object.keys(product.ingredientSelection).includes(id.toString()))
      group.ingredients = group.ingredients.filter((ingredient) => group.ingredientIds.includes(ingredient.id)).map((ingredient) => {
        try {
          ingredient.price = parseFloat(ingredient.sizes.filter((size) => size.size === product.selectedSize.size)[0].price)
        } catch (e) {
          ingredient.price = 0
        }
        return ingredient
      })
      return group
    }).filter((group) => group.ingredientIds.length).map(group => group.ingredients).flat().reduce((obj, item) => {
      obj[item.id] = item
      return obj
    }, {})
  }
  return false
}

export function sanitizeCart (cart) { // sanitize cart before sending the order
  const sanitizeItem = (item) => {
    const sanitized = {}
    const keep = ['id', 'selectedBase', 'selectedSize', 'products', 'ingredientSelection', 'qty', 'discount', 'free', 'allergies']

    Object.keys(item).filter(key => keep.includes(key)).forEach(key => {
      if (key === 'ingredientSelection') {
        sanitized[key] = ingredientUtils.sanitizePredefinedIngredients(item.ingredientSelection, item.items)
      }
      else if (key === 'selectedBase') {
        sanitized[key] = {
          id: item[key].id
        }
      } else if (key === 'selectedSize') {
        let size = {
          size: item[key].size
        }
        if(item[key].customSize) {
         size.customSize = true
        }
        sanitized[key] = size
      } else {
        sanitized[key] = item[key]
      }
    })
    return sanitized
  }

  const getItem = (item) => {
    if (Array.isArray(item.products)) {
      for (let i = 0; i < item.products.length; i++) {
        item.products[i] = sanitizeItem(item.products[i])
        if (item.products[i].products) {
          item.products[i] = getItem(item.products[i])
        }
      }
    }

    return sanitizeItem(item)
  }

  if (cart.length) {
    for (let i = 0; i < cart.length; i++) {
      cart[i] = getItem(cart[i])
    }
  }

  const fixSelectedSize = (item, parent = null) => {
    if (item.selectedSize && parent) {
      item.selectedSize = parent.selectedSize
    }
    if (item.products && item.products.length) {
      for (let i = 0; i < item.products.length; i++) {
        item.products[i] = fixSelectedSize(item.products[i], item)
      }
    }
    return item
  }

  // This code is commented out to be able to save the size of the order items in deals to the database.
  // if (cart.length) {
  //   for (let i = 0; i < cart.length; i++) {
  //     cart[i] = fixSelectedSize(cart[i])
  //   }
  // }

  return cart
}

export function processCart (cartData, menu, ingredients, timezone, storeSchedule = null, requestedTime = null) { // used for rebuilding the cart with necessary data and calculates the cart
  let cart = sanitizeCart(cartData)
  const ingredientGroups = menu.ingredient ? ingredientUtils.compileIngredientGroups(menu.ingredient.items, ingredients) : []
  const result = checkCart(cart, menu, timezone, ingredientGroups, storeSchedule, requestedTime)  // Removes the bad product from cart
  cart = result.cart  // Removes the bad product from cart
  const cartTree = JSON.parse(JSON.stringify(cart))
  cart = expandCart(cart, menu, ingredientGroups)
  const total = calculateCartTotal(cart)
  return { flat: formatCartContent(cart, total.total), cart: cartTree, removedItems: result.removedItems, removedItemsInDetail: result.removedItemsInDetail }
}

export function formatCartContent (cart, total = 0) {
  let idx = -1
  const formatItem = (item) => {
    idx++
    const price = item.price + item.extraPrice
    const formatted = {
      idx,
      product_id: item.product_type === 'halfNHalf' ? '-1': item.id,
      name: item.name,
      price: price ? price.toFixed(2) : null,
      size: item.selectedSize ? item.selectedSize.size : null,
      product_type: item.product_type,
      qty: item.qty ? item.qty : 1,
      products: [],
    }
    if (item.allergies && item.allergies.length) {
      formatted.allergy = item.allergies
    }
    if (item.discount) {
      formatted.discount = item.discount
    }

    if (Array.isArray(item.products)) {
      for (let j = 0; j < item.products.length; j++) {
        formatted.products[j] = formatItem(item.products[j])
      }
    }
    return formatted
  }

  for (let i = 0; i < cart.length; i++) {
    cart[i] = formatItem(cart[i])
  }

  let flatten = (items, parent_id = null) => {
    return items.reduce((acc, item) => {
      item.parent_id = parent_id
      acc.push(item)
      if (item.products) {
        acc = acc.concat(flatten(item.products, item.idx))
      }
      return acc
    }, [])
  }

  const order_items = flatten(cart).map(item => {
    delete item.products
    delete item.idx
    return item
  })

  return { total: total.toFixed(2), cart: order_items }
}

export function expandCart (cart, menu, ingredientGroups, correctProducts = false, freePizzaRewardDeal = null) {
  let menuItems = Object.values(menu).map(group => group.items).flat()
  const hnhCartItems = []
  for (let i = 0; i < cart.length; i++) {
    if (cart[i].id === -1) {
      hnhCartItems.push(cart[i])
    } else {
      if (cart[i].products && cart[i].products.length) {
        for (let j = 0; j < cart[i].products.length; j++) {
          if (cart[i].products[j].id === -1) {
            hnhCartItems.push(cart[i].products[j])
          }
        }
      }
    }
  }
  let hnhSize = []
  if (hnhCartItems.length) {
    for (let i = 0; i < hnhCartItems.length; i++) {
      if (hnhCartItems[i].products && hnhCartItems[i].products.length) {
        const hnhInMenu = menuItems.find(item => item.id === hnhCartItems[i].products[0].id)
        if (hnhInMenu) {
          hnhSize.push(...hnhInMenu.sizes)
        }
      }
    }
  }

  // Removing duplicate sizes
  hnhSize = hnhSize.filter((value, index, self) =>
    index === self.findIndex((t) => (
      t.size === value.size
    ))
  )

  menuItems.unshift(Object.assign(halfNHalfItem, { sizes: hnhSize }))
  const bases = ingredientUtils.getBases(ingredientGroups)

  for (let i = 0; i < cart.length; i++) {
    cart[i] = expandItem(cart[i], menu, menuItems, ingredientGroups, bases, freePizzaRewardDeal)
  }

  const filterChild = (product) => {
    if (product.product_type === 'pizza') {
      product.products = false
    }
    if (product.product_type === 'halfNHalf') {
      product.products = product.products.filter(child => child.product_type === 'pizza')
    }
    if (product.products && product.products.length) {
      for (let i = 0; i < product.products.length; i++) {
        product.products[i] = filterChild(product.products[i])
      }
    }
    return product
  }

  if (correctProducts) {
    for (let i = 0; i < cart.length; i++) {
      cart[i] = filterChild(cart[i])
    }
  }

  return cart
}

export function expandItem (item, menu, menuItems, ingredientGroups, bases, freePizzaRewardDeal = null) {
  if (item && item.id) {
    let menuItem = null
    if (freePizzaRewardDeal && item.id === freePizzaRewardDeal.id) {
      menuItem = Object.assign({}, freePizzaRewardDeal)
    } else {
      menuItem = menuItems.filter(product => product.id === item.id)[0]
    }
    if (menuItem) {
      item = Object.assign({}, menuItem, item)
      if (item.selectedSize && item.sizes && !item.selectedSize.customSize) {
        item.selectedSize = item.sizes.filter(size => size.size === item.selectedSize.size)[0]
      }
      if (item.selectedBase) {
        const base = bases.filter(base => parseInt(base.id) === parseInt(item.selectedBase.id))[0]
        if (base) {
          item.selectedBase = base
        }
        if (slug(base.name) !== 'deep-pan') {
          const selectedBase = {
            id: base.id,
            qty: 1,
            name: '+ ' + base.name,
            size: item.selectedSize.size,
            product_type: 'ingredient',
            price: null
          }
          if (Array.isArray(item.products)) {
            if (item.id === -1) {
              item.products.unshift(selectedBase)
            } else {
              item.products.push(selectedBase)
            }
          } else {
            item.products = [selectedBase]
          }
        }
      }
      if (Array.isArray(item.products)) {
        for (let i = 0; i < item.products.length; i++) {
          if (item.products[i].product_type !== 'ingredient') {
            if (['halfNHalf', 'deal'].includes(item.product_type)) {
              item.products[i].showSize = false
              if (item.product_type === 'halfNHalf') {
                item.products[i].showBase = false
              }
            }
            item.products[i] = expandItem(item.products[i], menu, menuItems, ingredientGroups, bases)
          }
        }
      }

      if (item.ingredientSelection) {
        const children = Object.entries(item.ingredientSelection).map(ingredient => {
          const prefix = Array(Math.abs(ingredient[1])).fill(0).map(item => (ingredient[1] >= 0 ? '+' : '-')).join('')
          const ingredientInMenu = menu.ingredient.items.find(item => parseInt(item.id) === parseInt(ingredient[0]))
          return {
            id: parseInt(ingredient[0]),
            qty: ingredient[1],
            name: prefix + ' ' + (ingredientInMenu ? ingredientInMenu.name : ''),
            size: item.selectedSize.size,
            price: null,
            product_type: 'ingredient'
          }
        })
        if (children) {
          if (item.products && Array.isArray(item.products)) {
            item.products.push(...children)
          } else {
            item.products = children
          }
        }
      }
      item = calculateItemTotal(item, ingredientGroups)
    }
  }
  return item
}

export function checkCart (cart, menu, timezone, ingredientGroups, storeSchedule, requestedTime) {
  // ingredientGroups parameter is passed differently from different places.
  if (timezone) {
    moment.tz.setDefault(timezone)
  }
  let menuItems = Object.values(menu).map(group => group.items).flat()

  let removedItemsInDetail = []

  const checkItem = (item) => {
    // This is recursive function
    // It checks if the product/ingredients is available with the given configuration (child count, size, availability)
    // It also checks if deal is available at current time
    // It returns null on fail.
    if (Array.isArray(item.products)) {
      const childCount = item.products.length
      for (let i = 0; i < childCount; i++) {
        item.products[i] = checkItem(item.products[i])
      }
      item.products = item.products.filter(product => product)
      if (childCount !== item.products.length) {
        removedItemsInDetail.push({
          item,
          errorMsg: 'Incorrect child item count. Child item was removed due to unavailability.'
        })
        return null
      }
    }
    if (item.id === -1) {
      if (!item.products || item.products.length !== 2) {
        // Hnh has incorrect child count
        removedItemsInDetail.push({
          item,
          errorMsg: 'Incorrect child item count. Child item was removed due to unavailability.'
        })
        return null
      }
      if (item.selectedSize && item.selectedSize.size === 'small') {
        // Small size in Hnh is not allowed (comparing old staging)
        removedItemsInDetail.push({
          item,
          errorMsg: 'H&H Small size is not allowed (comparing old staging).'
        })
        return null
      }
      const baseFound = menuItems.find(menuItem => menuItem.id === item.selectedBase.id)
      if (!baseFound) {
        // Base not available for the store
        removedItemsInDetail.push({
          item,
          errorMsg: 'Base ' + item.selectedBase.id + ' is not available in the store.'
        })
        return null
      }
      const baseSizeFound = baseFound.sizes.find(size => size.size === item.selectedSize.size)
      if (!baseSizeFound) {
        // Base available, but not with given size
        removedItemsInDetail.push({
          item,
          errorMsg: 'Base size ' + item.selectedSize.size + ' is not available in the store.'
        })
        return null
      }
      return item
    }
    const itemInMenu = menuItems.find((menuItem) => {
      if (menuItem.id === item.id) {
        if (item.selectedSize && !item.selectedSize.customSize) {
          const sizeFound = menuItem.sizes.find(size => size.size === item.selectedSize.size)
          if (sizeFound) {
            item.selectedSize = Object.assign({}, sizeFound)
            return true
          }
        } else {
          return true
        }
      }
      return false
    })
    if (!itemInMenu) {
      // Product not found with given size
      removedItemsInDetail.push({
        item,
        errorMsg: 'Size ' + item.selectedSize.size + ' is not available in menu.'
      })
      return null
    }
    if (itemInMenu.product_type === 'deal') {
      // Checking deal schedule and time
      const dealAvailable = checkDealSchedule(itemInMenu, storeSchedule, requestedTime)
      if (!dealAvailable) {
        removedItemsInDetail.push({
          item,
          errorMsg: itemInMenu.name + ' is not available on the day.'
        })
        return null
      }
    }
    if (item.products && ((itemInMenu.items.length !== item.products.length && itemInMenu.product_type !== 'side') || (itemInMenu.items.length < item.products.length && itemInMenu.product_type === 'side'))) {
      removedItemsInDetail.push({
        item,
        errorMsg: 'Item has incorrect child item count. Child item was removed due to unavailability.'
      })
      return null
    }
    if (itemInMenu.product_type === 'deal' && (!item.products || itemInMenu.items.length !== item.products.length)) {
      removedItemsInDetail.push({
        item,
        errorMsg: 'Deal have incorrect child item count. Child item was removed due to unavailability.'
      })
      return null
    }
    if (itemInMenu.settings && itemInMenu.settings.available_ingredients) {
      itemInMenu.settings.available_ingredients = itemInMenu.settings.available_ingredients.map(id => parseInt(id))
    }
    if (item.ingredientSelection) {
      const missingIngredientId = Object.keys(item.ingredientSelection).find(ingredient => !itemInMenu.settings.available_ingredients.includes(parseInt(ingredient)))
      if (missingIngredientId) {
        removedItemsInDetail.push({
          item,
          errorMsg: 'Ingredient ' + missingIngredientId + ' is not available for the item.'
        })
        return null
      }
      const ingredientSizeValid = Object.keys(item.ingredientSelection).every((ingredientId) => {
        const ingredientFound = menuItems.find(menuItem => menuItem.id === parseInt(ingredientId))
        if (!ingredientFound) {
          // Ingredient not available for the store
          removedItemsInDetail.push({
            item,
            errorMsg: 'Ingredient ' + ingredientId + ' is not available in the store.'
          })
          return false
        }
        const ingredientSizeFound = ingredientFound.sizes.find(size => size.size === item.selectedSize.size)
        if (!ingredientSizeFound) {
          // Ingredient available for the pizza, but not with given size
          removedItemsInDetail.push({
            item,
            errorMsg: ingredientFound.name + ' ' + item.selectedSize.size + ' is not available in the store.'
          })
          return false
        }
        return true
      })
      if (!ingredientSizeValid) {
        return null
      }
    }
    if (item.selectedBase && itemInMenu.product_type === 'pizza') {
      const basesGroupIndex = ingredientGroups.findIndex(group => group.name === 'Base')
      let availableBases = []
      if (basesGroupIndex >= 0) {
        availableBases = ingredientGroups[basesGroupIndex].ingredients.filter(base => itemInMenu.settings.available_ingredients.includes(parseInt(base.id)))
        if (!availableBases.length) {
          availableBases = ingredientGroups[basesGroupIndex].ingredients
        }
      }
      if (!availableBases.find(base => base.id === parseInt(item.selectedBase.id))) {
        // Base is not available for the pizza
        removedItemsInDetail.push({
          item,
          errorMsg: 'Base ' + item.selectedBase.id + ' not available for the pizza'
        })
        return null
      }
      const baseFound = menuItems.find(menuItem => menuItem.id === item.selectedBase.id)
      if (!baseFound) {
        // Base not available for the store
        removedItemsInDetail.push({
          item,
          errorMsg: 'Base ' + item.selectedBase.id + ' is not available in the store'
        })
        return null
      }
      const baseSizeFound = baseFound.sizes.find(size => size.size === item.selectedSize.size)
      if (!baseSizeFound) {
        // Base available for the pizza, but not with given size
        removedItemsInDetail.push({
          item,
          errorMsg: baseFound.name + ' ' + item.selectedSize.size + ' is not available in the store.'
        })
        return null
      }
    }
    return item
  }
  const removedItems = []
  if (cart.length) {
    for (let i = 0; i < cart.length; i++) {
      const item = JSON.parse(JSON.stringify(cart[i]))
      item.cartIndex = i
      cart[i] = checkItem(cart[i])
      if (!cart[i]) {
        removedItems.push(item)
      }
    }
    // Removing the empty cart items.
    cart = cart.filter(cartItem => cartItem)
  }
  removedItemsInDetail = removedItemsInDetail.map(item => {
    const newItem = { errorMsg: item.errorMsg, item: {} }
    if (item.item.id) {
      newItem.item.id = item.item.id
    }
    if (item.item.name) {
      newItem.item.name = item.item.name
    }
    if (item.item.selectedSize) {
      newItem.item.selectedSize = item.item.selectedSize.size
    }
    if (item.item.selectedBase) {
      newItem.item.selectedBase = item.item.selectedBase.id
    }
    if (item.item.ingredientSelection) {
      newItem.item.ingredientSelection = item.item.ingredientSelection
    }
    newItem.item = JSON.stringify(newItem.item)
    return newItem
  })
  return { cart, removedItems, removedItemsInDetail }
}

export function checkCartContent(cart, menu, timezone, storeSchedule, requestedTime) {
  const ingredientGroups = [ { name: 'Base', ingredients: [] } ]
  JSON.parse(JSON.stringify(menu)).ingredient.items.forEach((item) => {
    if (item.tags.includes('base')) {
      ingredientGroups[0].ingredients.push({ id: item.id, name: item.name })
    }
  })
  return checkCart(cart, menu, timezone, ingredientGroups, storeSchedule, requestedTime)
}
