import { mapActions, mapMutations, mapGetters } from 'vuex'
let checkFbLogin

export default {
  data () {
    return {
      email: '',
      password: '',
      confirmPassword: '',
      termsPrivacy: false,
      mailformat: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      showResetPassword: false
    }
  },
  computed: {
    ...mapGetters(['redirectTo']),
    emailState () {
      if (this.email) {
        return this.mailformat.test(this.email)
      }
      return null
    },
    passwordState () {
      if (this.password) {
        return this.password.length > 5 && this.password.length < 20
      }
      return null
    },
    confirmPasswordState () {
      if (this.confirmPassword) {
        return this.confirmPassword === this.password
      }
      return null
    },
    registerBtnDisabled () {
      if (this.isMobileApp) {
        // Will be undefined for web
        return this.emailState && this.passwordState && this.confirmPasswordState ? false : true
      }
      return this.emailState && this.passwordState && this.confirmPasswordState && this.termsPrivacy ? false : true
    },
    loginBtnDisabled () {
      return this.emailState && this.password ? false : true
    },
    forgotPasswordBtnDisabled () {
      return this.emailState ? false : true
    },
    changePasswordBtnDisabled () {
      return this.passwordState && this.confirmPasswordState ? false : true
    }
  },
  methods: {
    ...mapActions(['register', 'login', 'loginFacebook', 'logout', 'forgotPassword', 'resetPassword', 'verifylink']),
    ...mapMutations(['setUser', 'setToken', 'setRedirectTo', 'setAddresses', 'setAddressSelected']),
    registerUser () {
      this.showLoading()
      this.register({ email: this.email, password: this.password, password_confirmation: this.confirmPassword }).then((data) => {
        if (data.status === 'Success') {
          this.email = this.password = this.confirmPassword = ''
          this.termsPrivacy = false
        }
        this.showAlert(data.message, data.status === 'Success' ? 'success' : 'danger', data.status)
      }).finally(() => {
        this.hideLoading()
      })
    },
    loginUser () {
      this.showLoading()
      this.login({ email: this.email, password: this.password, platform: this.$platform }).then((data) => {
        this.showAlert(data.message, data.status === 'Success' ? 'success' : 'danger', data.status)
        if (data.status === 'Success') {
          if (this.redirectTo) {
            this.setRedirectTo('')
            return this.redirectToLogistic()
          }
          return this.redirectAfterLogin()
        }
      }).finally(() => {
        this.hideLoading()
      })
    },
    loginFacebookHasData (data) {
      this.setUser(data.user)
      this.setToken(data.token)
      this.setAddresses(data.addresses)
      if(data.addresses) {
        const defaultAddress = data.addresses.find(address => address.is_default)
        if (defaultAddress) {
          this.setAddressSelected(defaultAddress)
        }
      }
      if (this.redirectTo) {
        this.setRedirectTo('')
        this.redirectToLogistic()
      }
      clearInterval(checkFbLogin)
      localStorage.removeItem('userData')
      this.redirectAfterLogin()
    },
    loginUserFacebook () {
      this.loginFacebook().then((data) => {
        if (data.status === 'Error') {
          this.showAlert(data.message, 'danger', 'Error')
        } else {
          const w = 580
          const h = 400
          const left = (window.screen.width / 2) - (w / 2)
          const top = (window.screen.height / 2) - (h / 2)
          window.open(data, 'connect', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left)

          checkFbLogin = setInterval(() => {
            if (localStorage.getItem('userData') !== null) {
             try {
               this.loginFacebookHasData(JSON.parse(localStorage.getItem('userData')))
             } catch (e) {
              console.log('loginFacebookHasData error ', e)
             }
            }
          }, 1000)
        }
      })
    },
    logoutUser () {
      this.showLoading()
      this.logout().then((data) => {
        if (data.status === 'Error') {
          return this.redirectAfterLogout()
          // this.showAlert(data.message, data.status === 'Success' ? 'success' : 'danger', data.status) // we should logout the user no matter what
        } else {
          return this.redirectAfterLogout()
        }
      }).finally(() => {
        this.hideLoading()
      })
    },
    passwordForgot () {
      this.showLoading()
      this.forgotPassword({ email: this.email }).then((data) => {
        this.showAlert(data.message, data.status === 'Success' ? 'success' : 'danger', data.status)
        if (data.status === 'Success') {
          this.email = ''
        }
      }).finally(() => {
        this.hideLoading()
      })
    },
    passwordReset () {
      this.showLoading()
      this.resetPassword({ id: this.$route.query.id, password: this.password, password_confirmation: this.confirmPassword }).then((data) => {
        this.showAlert(data.message, data.status === 'Success' ? 'success' : 'danger', data.status)
        if (data.status === 'Success') {
          return this.redirectToLogin()
        }
      }).finally(() => {
        this.hideLoading()
      })
    }
  },
  mounted () {
    if (this.$route.query.expires && this.$route.query.signature && this.$route.query.id && this.$route.name !== 'auth-verifyRegister') {
      const data = {
        expires: this.$route.query.expires,
        signature: this.$route.query.signature,
        id: this.$route.query.id
      }
      this.showLoading()
      return this.verifylink(data).then((resp) => {
        if (resp.status === 'Success') {
          this.showResetPassword = true
        } else {
          this.showAlert(resp.message, 'danger', resp.status)
        }
      }).finally(() => {
        this.hideLoading()
      })
    }
  }
}
