import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=59ccdef2&scoped=true"
import script from "./Footer.vue?vue&type=script&lang=js"
export * from "./Footer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59ccdef2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Menu: require('/home/uattopspizzaco/builds/fZHTZxRpf/0/standbyteam/topspizza.co.uk/frontend/web/components/Menu.vue').default,Footer: require('/home/uattopspizzaco/builds/fZHTZxRpf/0/standbyteam/topspizza.co.uk/frontend/web/components/footer/Footer.vue').default})
