// info Vuex module
import slug from 'slug'

const state = () => ({
  infoPages: [],
  storeInfo: null
})

const getters = {
  infoPages (state) {
    return state.infoPages
  },
  storeInfo (state) {
    return state.storeInfo
  }
}

const mutations = {
  setInfoPages (state, infoPages) {
    state.infoPages = infoPages
  },
  setStoreInfo (state, storeInfo) {
    state.storeInfo = storeInfo
  }
}

const actions = {
  getInfoPages (context) {
    const requestData = { platform: this.$platform }
    if (context.getters.currentStore) {
      requestData.store_id = context.getters.currentStore.id
    }
    return this.$api.getInfoPages(requestData).then((resp) => {
      resp.data.data = resp.data.data.map((page) => {
        page.url = '/info/' + slug(page.title)
        return page
      })
      context.commit('setInfoPages', resp.data.data)
      return resp.data.data
    }).catch((e) => {
      if (e.response) {
        return e.response.data
      }
      console.log(e)
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
