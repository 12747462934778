import Vuex from 'vuex'
import store from '../../common/store/index'

const createStore = () => {
  return new Vuex.Store({
    modules: store
  })
}

export default createStore
