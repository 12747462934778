import { mapGetters, mapMutations } from 'vuex'
import pluralize from 'pluralize'

export default function (mode = 'default') {
  let getters = ['currentUser', 'products', 'availableLoyaltyPoints', 'reorderTooltip']
  let mutations = ['setReorderTooltip']
  if (mode === 'pos') {
    getters = ['products']
    mutations = []
  }
  return {
    computed: {
      ...mapGetters(getters),
      menuItems () {
        return Object.keys(this.products).filter(name => name !== 'ingredient').map((name) => {
          return {
            name: pluralize(name),
            link: '/menu/' + pluralize(name)
          }
        })
      }
    },
    methods: {
      ...mapMutations(mutations)
    }
  }
}