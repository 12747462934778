//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters } from 'vuex'
import Menu from '../Menu'
import HeaderTop from './HeaderTop'

export default {
  name: 'Header',
  components: {
    Menu,
    HeaderTop
  },
  props: {
    headerTop: {
      type: Boolean,
      default () {
        return true
      }
    }
  },
  computed: {
    ...mapGetters(['currentStore'])
  }
}
