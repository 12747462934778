// order Vuex module

const state = () => ({
  orderHistory: {
    orders: [],
    links: null,
    meta: null
  }
})

const getters = {
  orderHistory (state) {
    return state.orderHistory
  }
}

const mutations = {
  setOrderHistory (state, data) {
    state.orderHistory.orders = data.data
    state.orderHistory.links = data.links
    state.orderHistory.meta = data.meta
  }
}

const actions = {
  getOrderHistory (context, data) {
    return this.$api.orders(data).then((resp) => {
      context.commit('setOrderHistory', resp.data.data)
      return resp.data
    }).catch((e) => {
      if (e.response === undefined) {
        return false
      }
      if (e.response.status === 401) {
        context.dispatch('logout')
      }
      return e.response.data
    })
  },
  rateOrder (context, data) {
    return this.$api.rateOrder(data).then((resp) => {
      return resp.data
    }).catch((e) => {
      if (e.response === undefined) {
        return false
      }
      return e.response.data
    })
  },
  getOrderDetail (context, data) {
    return this.$api.orderDetail(data).then((resp) => {
      return resp.data
    }).catch((e) => {
      if (e.response === undefined) {
        return false
      }
      return e.response.data
    })
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}