import { track } from '@minimal-analytics/ga4'

export default ({ env }, inject) => {
  // console.log('googleAnalytics plugin')
  window.minimalAnalytics = {
    trackingId: env.ga4id
  }
  // console.log('googleAnalylics')
  track()
  inject('track', track)
}
