// inject the api definitions
export default ({ app, env }, inject) => {
  inject('appName', env.appName)
  inject('ga4Debug', env.ga4Debug)
  inject('platform', 'web')
  const getPageTitle = (pageTitle) => {
    return pageTitle + ' - Pizza Delivery, Takeaway And Deals In Britain | Order Online With Tops Pizza'
  }
  inject('getPageTitle', getPageTitle)
  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }
  inject('capitalize', capitalize)
}
