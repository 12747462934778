//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import menu from '../../common/mixins/menu'

export default {
  name: 'Menu',
  mixins: [menu()],
  props: {
    type: {
      type: String,
      default () {
        return 'Header'
      }
    }
  }
}
