// inject the api definitions
import api from '../../common/api'

export default function ({ $axios, store }, inject) {
  const http = {
    get (url, requestData) {
      return $axios.get(url, { params: requestData, headers: { Authorization: 'Bearer ' + store.state.user.token } })
    },
    post (url, requestData) {
      return $axios.post(url, requestData, { headers: { Authorization: 'Bearer ' + store.state.user.token } })
    },
    put (url, requestData) {
      return $axios.put(url, requestData, { headers: { Authorization: 'Bearer ' + store.state.user.token } })
    },
    delete (url, requestData) {
      return $axios.delete(url, { params: requestData, headers: { Authorization: 'Bearer ' + store.state.user.token } })
    }
  }
  inject('api', api(http))
}
