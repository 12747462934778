//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapActions, mapGetters, mapMutations } from 'vuex'
import storeSearch from '../../common/mixins/storeSearch'
import alertWeb from '../../common/mixins/alertWeb'
import redirectWeb from '../../common/mixins/redirectWeb'

export default {
  name: 'StoreModal',
  mixins: [alertWeb, storeSearch, redirectWeb],
  data () {
    return {
      postcode: ''
    }
  },
  computed: {
    ...mapGetters(['stores', 'currentStore', 'isStoreClosed'])
  },
  methods: {
    ...mapActions(['setCurrentStore']),
    ...mapMutations(['setCurrentItem']),
    goToStores () {
      this.setCurrentItem(null)
      this.$router.push({ path: '/stores', query: { from: this.$route.path } })
    }
  }
}
