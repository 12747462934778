import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      addedProduct: null,
      addonSelection: [],
      extraItems: false
    }
  },
  computed: {
    ...mapGetters(['products']),
    addonOptions () {
      const products = JSON.parse(JSON.stringify(this.products))
      try {
        return this.addedProduct.items.map((item) => {
          return Object.values(products)
            .map(group => group.items)
            .flat()
            .filter(product => item.settings.items.includes(product.id))
        })
      } catch (e) {}
      return false
    }
  }
}