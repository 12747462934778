import moment from 'moment'
import 'moment-timezone'

const state = () => ({
  timezone: null,
  currentTime: null
})

const getters = {
  timezone (state) {
    return state.timezone
  },
  currentTime (state) {
    return state.currentTime
  },
  currentMoment (state, getters) {
    return getters.currentTime && getters.timezone ? moment.unix(getters.currentTime).tz(getters.timezone) : null
  }
}

const mutations = {
  setTimezone (state, timezone) {
    state.timezone = timezone
  },
  setCurrentTime (state, currentTime) {
    state.currentTime = currentTime
  }
}

const actions = {
  getServerTime (context, data) {
    return this.$api.getServerTime().then((resp) => {
      context.commit('setTimezone', resp.data.data.appTimezone)
      context.commit('setCurrentTime', resp.data.data.currentTime)
    }).catch((e) => {
      if (e.response) {
        return e.response.data
      }
      if (this.$platform === 'pos' && this.$webPos) {
        // window.location.reload()
      }
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
