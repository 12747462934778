// store Vuex module

const state = () => ({
  stores: []
})

const getters = {
  stores (state) {
    return state.stores
  }
}

const mutations = {
  setStores (state, stores) {
    state.stores = stores
  }
}

const actions = {
  getStores (context) {
    return this.$api.getStores().then((resp) => {
      context.commit('setStores', resp.data.data)
      return resp.data
    }).catch(e => e.response.data)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
