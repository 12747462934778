import { getdiscount } from '../utils/vouchers'
import * as orderProcessor from '../utils/orderProcessor'

const state = () => ({
  voucher: null,
  discount: 0,
  freeProducts: []
})

const getters = {
  voucher (state) {
    return state.voucher
  },
  discount (state) {
    return state.discount
  },
  freeProducts (state) {
    return state.freeProducts
  },
  totalDiscount (state, getters) {
    let discount = 0
    if (getters.voucher) {
      if (getters.voucher.type === 'reusable-value') {
        discount = getters.voucher.used_amount_order ?? getters.voucher.left_amount
        if (getters.total < discount) {
          discount = getters.total
        }
      } else {
        getters.cart.forEach((item) => {
          if (item.discount) {
            discount = discount + item.discount
          }
        })
      }
    }
    return parseFloat(discount).toFixed(2)
  }
}

const mutations = {
  setVoucher(state, data) {
    state.voucher = data
  },
  setDiscount(state, data) {
    state.discount = data
  },
  setFreeProducts(state, data) {
    state.freeProducts = data
  },
  removeVoucher (state) {
    state.voucher = null
    state.discount = 0
    state.freeProducts = []
  }
}

const actions = {
  async getVoucher (context, data) {
    await context.dispatch('removeVoucherAndDiscount')
    return this.$api.getVoucher(data).then((resp) => {
      const discountData = getdiscount(resp.data.data, JSON.parse(JSON.stringify(context.getters.cart)), context.getters.products)
      if (discountData
        && ((['reusable-value', 'discount'].includes(resp.data.data.type)
        && discountData.voucherApplied) || (!['reusable-value', 'discount'].includes(resp.data.data.type) && discountData.freeProducts.length))) {
          if (!(context.getters.voucher && JSON.stringify(context.getters.voucher) === JSON.stringify(resp.data.data))) {
            const voucher = resp.data.data
            if (['product', 'deal'].includes(voucher.type)) {
              Object.assign(voucher, { freeProductsAllowedQty: discountData.freeProductsAllowedQty })
            }
            context.commit('setVoucher', voucher)
            context.commit('setDiscount', discountData.discount)
            context.commit('setFreeProducts', discountData.freeProducts)
            context.commit('setCart', discountData.cart)
          }
      }
      resp.data.data.discountData = discountData
      return resp.data
    }).catch(e => {
      context.dispatch('removeVoucherAndDiscount')
      return e.response.data
    })
  },
  removeVoucherAndDiscount (context) {
    if (context.getters.voucher) {
      context.commit('removeVoucher')
      context.commit('removeCartDiscount')
      const { total } = orderProcessor.calculateCartTotal(context.getters.cart, context.getters.ingredientGroups)
      context.commit('setCartTotal', total)
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}