// vouchers logic
import { calculateCartTotal, expandCart, sanitizeCart } from './orderProcessor.js'
import * as ingredientUtils from './ingredients.js'

function areVoucherFieldsValid (voucher) {
  if (!voucher.settings) {
    return false
  }
  if (voucher.type === 'reusable-value') {
    if (!voucher.settings.amount || isNaN(parseFloat(voucher.settings.amount))) {
      return false
    }
  }
  if (['discount', 'product', 'deal'].includes(voucher.type)) {

    if ((voucher.settings.min_spend && isNaN(parseFloat(voucher.settings.min_spend)))
    || (voucher.settings.num_use && isNaN(parseFloat(voucher.settings.num_use)))) {
      return false
    }
    if (!voucher.settings.sizes || !voucher.settings.sizes.length) {
      return false
    }
    if (voucher.type === 'discount') {
      if ((!voucher.settings.product_type || !voucher.settings.product_type.length) 
      && (!voucher.settings.products || !voucher.settings.products.length)) {
        return false
      }
      if ((voucher.settings.is_percent === null || parseFloat(voucher.settings.is_percent) === 0) 
      && isNaN(parseFloat(voucher.settings.amount))) {
        return false
      }
      if (parseFloat(voucher.settings.is_percent) === 1 && isNaN(parseFloat(voucher.settings.percentage))) {
        return false
      }
    }
    if (['product', 'deal'].includes(voucher.type)) {
      if (voucher.settings.max_qty && isNaN(parseFloat(voucher.settings.max_qty))) {
        return false
      }
      if (!voucher.settings.free_products || !voucher.settings.free_products.length
        || !voucher.settings.free_product_sizes || !voucher.settings.free_product_sizes.length) {
        return false
      }
      if (voucher.type === 'product') {
        if ((!voucher.settings.product_type || !voucher.settings.product_type.length) 
        && (!voucher.settings.products || !voucher.settings.products.length)) {
          return false
        }
      }
      if (voucher.type === 'deal') {
        if (!voucher.settings.deals || !voucher.settings.deals.length) {
          return false
        }
      }
    }
  }
  return true
}

function voucherDiscount (voucher, cart) {
  let isPercent = parseInt(voucher.settings.is_percent)
  const specificProduct = parseInt(voucher.settings.specific_product)
  voucher.settings.product_type = voucher.settings.product_type ? voucher.settings.product_type.map(product_type_id => parseInt(product_type_id)) : null
  voucher.settings.products = voucher.settings.products ? voucher.settings.products.map(productId => parseInt(productId)) : null
  let minTotal = 0
  let discount = 0
  let voucherApplied = false
  cart.forEach((item) => {
    if (!isNaN(parseInt(voucher.settings.min_spend_including_deals)) && parseInt(voucher.settings.min_spend_including_deals) === 1) {
      minTotal += ((item.price + item.extraPrice) * item.qty)
    } else if (item.product_type !== 'deal') {
      minTotal += ((item.price + item.extraPrice) * item.qty)
    }
  })
  if (isNaN(parseFloat(voucher.settings.min_spend))) {
    return { cart, discount, freeProducts: [], voucherApplied, message: 'Voucher settings are incomplete' }
  } else if (parseFloat(voucher.settings.min_spend) > minTotal) {
    return { cart, discount, freeProducts: [], voucherApplied, message: 'You do not meet the minimum requirement for this voucher!' }
  }
  let leftQty = parseInt(voucher.settings.max_qty)
  cart = cart.filter((item) => {
    if (!item.free) {
      if (!specificProduct) {
          if (voucher.settings.product_type.includes(item.product_type_id)
        && (voucher.settings.sizes.includes('any') || voucher.settings.sizes.includes(item.selectedSize.size))) {

          let discountQty = item.qty
          while (discountQty > leftQty && discountQty > 0) {
            discountQty--
          }
          leftQty = leftQty - discountQty
          if (discountQty) {
            if (isPercent) {
              item.discount = ((item.price + item.extraPrice) * discountQty * voucher.settings.percentage) / 100
            } else {
              let discountPrice = item.price
              if (discountPrice > voucher.settings.amount) {
                discountPrice = voucher.settings.amount
              }
              item.discount = discountPrice * discountQty
            }
            discount += item.discount
            voucherApplied = true
            return true
          }
        }
      } else {
        if (voucher.settings.products.includes(item.id)
        && (voucher.settings.sizes.includes('any') || voucher.settings.sizes.includes(item.selectedSize.size))) {
          let discountQty = item.qty
          while (discountQty > leftQty && discountQty > 0) {
            discountQty--
          }
          leftQty = leftQty - discountQty
          if (discountQty) {
            if (isPercent) {
              item.discount = ((item.price + item.extraPrice) * discountQty * voucher.settings.percentage) / 100
            } else {
              let discountPrice = item.price
              if (discountPrice > voucher.settings.amount) {
                discountPrice = voucher.settings.amount
              }
              item.discount = discountPrice * discountQty
            }
            discount += item.discount
            voucherApplied = true
            return true
          }
        }
      }
      if (item.discount) {
        delete item.discount
      }
      return true
    }
    return false
  })
  return { cart, discount, freeProducts: [], voucherApplied, message: !discount ? 'Please add the correct items into the cart' : null }
}

function voucherDeal (voucher, cart, products) {
  const productsExceptIngredientsDeals = []
  Object.keys(products).forEach(group => {
    if (group !== 'deal' && group !== 'ingredient') {
      productsExceptIngredientsDeals.push(...products[group].items)
    }
  })
  voucher.settings.deals = voucher.settings.deals.map(productId => parseInt(productId))
  voucher.settings.free_products = voucher.settings.free_products.map(productId => parseInt(productId))
  
  let discount = 0
  let voucherApplied = false
  let minTotal = 0

  cart.forEach((item) => {
    if (!item.discount) {
      if (!isNaN(parseInt(voucher.settings.min_spend_including_deals)) && parseInt(voucher.settings.min_spend_including_deals) === 1) {
        minTotal += ((item.price + item.extraPrice) * item.qty)
      } else if (item.product_type !== 'deal') {
        minTotal += ((item.price + item.extraPrice) * item.qty)
      }
    }
  })
  if (isNaN(parseFloat(voucher.settings.min_spend))) {
    return { cart, discount, freeProducts: [], voucherApplied, message: 'Voucher settings are incomplete', freeProductsAllowedQty: 0 }
  } else if (parseFloat(voucher.settings.min_spend) > minTotal) {
    return { cart, discount, freeProducts: [], voucherApplied, message: 'You do not meet the minimum requirement for this voucher!', freeProductsAllowedQty: 0 }
  }
  
  let freeProducts = []
  let validItemQty = 0  // The total item qty in cart on which voucher is applied
  // Preparing the free product list.
  cart.forEach(cartItem => {
    if (!cartItem.free && voucher.settings.deals.includes(cartItem.id)
    && (voucher.settings.sizes.includes('any') || voucher.settings.sizes.includes(cartItem.selectedSize.size))) {
      validItemQty += cartItem.qty
      if (!freeProducts.length) {
        freeProducts = productsExceptIngredientsDeals.filter(product => voucher.settings.free_products.includes(product.id))
      }
    }
  })

  if (freeProducts.length) {
    // Updating the free product items sizes and discount for those sizes.
    // Also adding flag free to items.
    freeProducts = freeProducts.map((product) => {
      if (!voucher.settings.free_product_sizes.includes('any')) {
        product.sizes = product.sizes.filter(size => {
          return voucher.settings.free_product_sizes.includes(size.size)
        })
      }
      product.sizes = product.sizes.map((size) => {
        size.discount = parseFloat(size.price)
        return size
      })
      if (product.selectedSize) {
        delete product.selectedSize
      }
      if (product.price) {
        delete product.price
      }
      if (product.extraPrice) {
        delete product.extraPrice
      }
      if (product.qty) {
        delete product.qty
      }
      product.free = true
      return product
    })
  }

  let leftQty = parseInt(voucher.settings.max_qty)
  leftQty = leftQty < validItemQty ? leftQty : validItemQty

  if (cart.length) {
    // Updating the cart items discount, qty and flag free as per the voucher settings.
    // We reduce the qty of free items in case if it's more than voucher max qty.
    // If voucher max qty is consumed or criteria (size and id should exist in free product list) doesn't fit, we drop/skip the free item.
    cart = cart.filter((item) => {
      if (item.free) {
        const itemFound = freeProducts.find(item2 => item2.id === item.id)
        if (itemFound) {
          const sizeFound = itemFound.sizes.find(size => size.size === item.selectedSize.size)
          if (sizeFound) {
            while (item.qty > leftQty && item.qty > 0) {
              item.qty--
            }
            leftQty = leftQty - item.qty
            if (item.qty) {
              item.discount = parseFloat((item.qty * item.price).toFixed(2))
              discount += item.discount
              voucherApplied = true
              return true
            }
          }
        }
      } else {
        if (item.discount) {
          delete item.discount
        }
        return true
      }
      return false
    })
  }
  freeProducts = freeProducts.filter(freeProduct => freeProduct.sizes.length)
  let message = null
  if (!freeProducts.length) {
    message = 'Voucher free product is not available on the store'
  }
  if (!validItemQty) {
    message = 'Please add the correct items into the cart'
  }
  return { cart, discount, freeProducts, voucherApplied, message, freeProductsAllowedQty: leftQty }
}

function voucherProduct (voucher, cart, products) {
  const productsExceptIngredientsDeals = []
  Object.keys(products).forEach(group => {
    if (group !== 'deal' && group !== 'ingredient') {
      productsExceptIngredientsDeals.push(...products[group].items)
    }
  })
  const specificProduct = parseInt(voucher.settings.specific_product)
  if (!specificProduct) {
    voucher.settings.product_type = voucher.settings.product_type.map(product_type_id => parseInt(product_type_id))
  } else {
    voucher.settings.products = voucher.settings.products.map(productId => parseInt(productId))
  }
  voucher.settings.free_products = voucher.settings.free_products.map(productId => parseInt(productId))
  
  let minTotal = 0
  let discount = 0
  let voucherApplied = false

  cart.forEach((item) => {
    if (!item.discount) {
      if (!isNaN(parseInt(voucher.settings.min_spend_including_deals)) && parseInt(voucher.settings.min_spend_including_deals) === 1) {
        minTotal += ((item.price + item.extraPrice) * item.qty)
      } else if (item.product_type !== 'deal') {
        minTotal += ((item.price + item.extraPrice) * item.qty)
      }
    }
  })
  if (isNaN(parseFloat(voucher.settings.min_spend))) {
    return { cart, discount, freeProducts: [], voucherApplied, message: 'Voucher settings are incomplete', freeProductsAllowedQty: 0 }
  } else if (parseFloat(voucher.settings.min_spend) > minTotal) {
    return { cart, discount, freeProducts: [], voucherApplied, message: 'You do not meet the minimum requirement for this voucher!', freeProductsAllowedQty: 0 }
  }

  let freeProducts = []
  let validItemQty = 0  // The total item qty in cart on which voucher is applied
  // Preparing the free product list.
  cart.forEach(cartItem => {
    if (!cartItem.free) {
      if (!specificProduct) {
        if (voucher.settings.product_type.includes(cartItem.product_type_id)
        && (voucher.settings.sizes.includes('any') || voucher.settings.sizes.includes(cartItem.selectedSize.size))) {
          validItemQty += cartItem.qty
          if (!freeProducts.length) {
            freeProducts = productsExceptIngredientsDeals.filter(product => voucher.settings.free_products.includes(product.id))
          }
        }
      } else {
        if (voucher.settings.products.includes(cartItem.id)
        && (voucher.settings.sizes.includes('any') || voucher.settings.sizes.includes(cartItem.selectedSize.size))) {
          validItemQty += cartItem.qty
          if (!freeProducts.length) {
            freeProducts = productsExceptIngredientsDeals.filter(product => voucher.settings.free_products.includes(product.id))
          }
        }
      }
    }
  })

  if (freeProducts.length) {
    // Updating the free product items sizes and discount for those sizes.
    // Also adding flag free to items.
    freeProducts = freeProducts.map((product) => {
      if (!voucher.settings.free_product_sizes.includes('any')) {
        product.sizes = product.sizes.filter(size => {
          return voucher.settings.free_product_sizes.includes(size.size)
        })
      }
      product.sizes = product.sizes.map((size) => {
        size.discount = parseFloat(size.price)
        return size
      })
      product.free = true
      return product
    })
  }


  let leftQty = parseInt(voucher.settings.max_qty)
  leftQty = leftQty < validItemQty ? leftQty : validItemQty

  if (cart.length) {
    // Updating the cart items discount, qty and flag free as per the voucher settings.
    // We reduce the qty of free items in case if it's more than voucher max qty.
    // If voucher max qty is consumed or criteria (size and id should exist in free product list) doesn't fit, we drop/skip the free item.
    cart = cart.filter((item) => {
      if (item.free) {
        const itemFound = freeProducts.find(item2 => item2.id === item.id)
        if (itemFound) {
          const sizeFound = itemFound.sizes.find(size => size.size === item.selectedSize.size)
          if (sizeFound) {
            while (item.qty > leftQty && item.qty > 0) {
              item.qty--
            }
            leftQty = leftQty - item.qty
            if (item.qty) {
              item.discount = parseFloat((item.qty * item.price).toFixed(2))
              discount += item.discount
              voucherApplied = true
              return true
            }
          }
        }
      } else {
        if (item.discount) {
          delete item.discount
        }
        return true
      }
      return false
    })
  }
  freeProducts = freeProducts.filter(freeProduct => freeProduct.sizes.length)
  let message = null
  if (!freeProducts.length) {
    message = 'Voucher free product is not available on the store'
  }
  if (!validItemQty) {
    message = 'Please add the correct items into the cart'
  }
  return { cart, discount, freeProducts, voucherApplied, message, freeProductsAllowedQty: leftQty }
}

function voucherReusable (voucher, cart) {
  voucher.left_amount = parseFloat(voucher.left_amount)
  cart = cart.map((item) => {
    if (item.free) {
      delete item.free
    }
    if (item.discount) {
      delete item.discount
    }
    return item
  })
  const data = calculateCartTotal(cart, false, voucher.left_amount)
  return { cart: data.cart, discount: data.discount, freeProducts: [], voucherApplied: data.discount ? true : false, message: null }
}

export function getdiscount (voucher, cart, products) {
  // console.log('areVoucherFieldsValid', areVoucherFieldsValid(voucher))
  if (!areVoucherFieldsValid(voucher)) {
    return null
  }
  if (voucher.type === 'reusable-value') {
    return voucherReusable(voucher, cart)
  }
  if (voucher.type === 'discount') {
    return voucherDiscount(voucher, cart)
  }
  if (voucher.type === 'deal') {
    return voucherDeal(voucher, cart, JSON.parse(JSON.stringify(products)))
  }
  if (voucher.type === 'product') {
    return voucherProduct(voucher, cart, JSON.parse(JSON.stringify(products)))
  }
  return null
}

export function processdiscount (voucher, cart, menu, ingredients) {
  const ingredientGroups = menu.ingredient ? ingredientUtils.compileIngredientGroups(menu.ingredient.items, ingredients) : []
  cart = expandCart(cart, menu, ingredientGroups, true)
  let data = getdiscount(voucher, cart, menu)
  if (!data) {
    cart = cart.map((item) => {
      if (item.free) {
        delete item.free
      }
      if (item.discount) {
        delete item.discount
      }
      return item
    })
    data = calculateCartTotal(cart)
    const resp = { cart: data.cart, discount: data.discount, voucherApplied: false }
    return resp
  }
  const resp = { cart: sanitizeCart(data.cart), discount: data.discount, voucherApplied: data.voucherApplied }
  return resp
}