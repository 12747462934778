export default function ({ store, redirect }) {
  if (store.getters.type === 'delivery') {
    if (!store.getters.addressSelected) {
      return redirect('/order/logistic')
    }
    if (!store.getters.addressSelected.store || (store.getters.addressSelected.store.id !== store.getters.currentStore.id)) {
      return redirect('/order/logistic')
    }
  }
}
