import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions(['setCurrentStore', 'checkCartContent']),
    searchStore () {
      this.loading = true
      this.setCurrentStore({ postcode: this.postcode }).then(async (data) => {
        if (data.status === 'Error') {
          return this.showAlert(data.message, 'danger', data.status)
        }
        this.postcode = ''
        this.triggerStorePopup()
        if (this.$platform === 'web') {
          this.setCurrentItem(null)
        }
        this.redirectAfterStoreSearch()
        const { removedItems } = await this.checkCartContent()
        if (removedItems.length > 0) {
          let msg = 'We do not sell the following products (or deal configuration) at the selected store: \n\n'
          removedItems.forEach(item => {
            msg += item.name
          })
          this.showAlert(msg, 'danger')
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
