import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters(['isStoreClosed'])
  },
  methods: {
    showAlert (message, variant = 'success', title = '') {
      return this.$root.$bvToast.toast(message, {
        title,
        variant,
        solid: false,
        noHoverPause: true,
        autoHideDelay: 3000
      })
    },
    hideModal () {
      this.$bvModal.hide('store-modal')
    },
    triggerStorePopup () {
      if (!this.isStoreClosed) {
        this.hideModal()
      }
    },
    showLoading () {
      this.loading = true
    },
    hideLoading () {
      this.loading = false
    }
  }
}
