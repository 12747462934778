//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters } from 'vuex'
import slug from 'slug'
import StoreDetail from '@/components/StoreDetail'

export default {
  name: 'HeaderTop',
  components: {
    StoreDetail
  },
  data () {
    return {
      storeDetailShow: false
    }
  },
  computed: {
    ...mapGetters(['currentStore', 'currentUser', 'totalItems', 'total', 'isStoreClosed']),
    name () {
      if (this.currentUser) {
        return this.currentUser.first_name ? this.currentUser.first_name + ' ' + this.currentUser.last_name : (this.currentUser.name ? this.currentUser.name : 'Pizza Lover')
      }
      return ''
    },
    storeSlug () {
      if (this.currentStore) {
        return slug(this.currentStore.name)
      }
      return ''
    }
  },
  watch: {
    isStoreClosed (newValue) {
      if (newValue) {
        this.$bvModal.show('store-modal')
      }
    }
  },
  mounted () {
    if (this.isStoreClosed) {
      this.$bvModal.show('store-modal')
    }
  },
  methods: {
    storeDetail () {
      if (this.currentStore) {
        this.storeDetailShow = !this.storeDetailShow
      } else {
        this.$bvModal.show('store-modal')
      }
    },
    goToCart () {
      if (this.totalItems === 0) {
        this.$bvToast.toast('Basket is empty!', {
          title: '',
          autoHideDelay: 5000,
          appendToast: false,
          variant: 'danger',
          toastClass: 'top-notice'
        })
      } else {
        this.$router.push('/order/cart')
      }
    }
  }
}
