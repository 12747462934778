import Echo from 'laravel-echo'

window.io = require('socket.io-client')

function getHeaders ({ app }) {
  const headers = {}

  return headers
}

export default (ctx, inject) => {
  const options = {"broadcaster":"socket.io","plugins":null,"authModule":false,"connectOnLogin":true,"disconnectOnLogout":true,"host":"topspizza.co.uk"}
  options.auth = options.auth || {}
  options.auth.headers = {
    ...options.auth.headers,
    ...getHeaders(ctx)
  }

  const echo = new Echo(options)

  ctx.$echo = echo
  inject('echo', echo)
}
