export const ingredientLimit = 8
export const ingredientFreeSwaps = 4

// blueprint for half n half
export const halfNHalfItem = {
  id: -1,
  qty: 1,
  assets: null,
  cartIndex: null,
  description: 'Turn one delicious pizzas into two by Picking two pizzas on one base',
  end_date: false,
  end_hours: '',
  extraPrice: 1,
  items: [null, null],
  name: 'Make It Half & Half',
  price: 0,
  product_type: 'halfNHalf',
  products: [null, null],
  selectedSize: false,
  sizes: [],
  start_date: false,
  start_hours: false,
  showSize: true,
  showBase: true,
  tags: []
}

export const allSizes = [
  {
    description: null,
    price: null,
    size: "small"
  },
  {
    description: null,
    price: null,
    size: "medium"
  },
  {
    description: null,
    price: null,
    size: "large"
  },
  {
    description: null,
    price: null,
    size: "supersize"
  },
  {
    description: null,
    price: null,
    size: "giant"
  }
]
