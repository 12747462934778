const state = {
  redirectTo: ''
}

const getters = {
  redirectTo (state) {
    return state.redirectTo
  }
}

const mutations = {
  setRedirectTo (state, redirectTo) {
    state.redirectTo = redirectTo
  }
}

const actions = {
}

export default {
  state,
  getters,
  actions,
  mutations
}
