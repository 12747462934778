const middleware = {}

middleware['currentAddress'] = require('../middleware/currentAddress.js')
middleware['currentAddress'] = middleware['currentAddress'].default || middleware['currentAddress']

middleware['currentOrder'] = require('../middleware/currentOrder.js')
middleware['currentOrder'] = middleware['currentOrder'].default || middleware['currentOrder']

middleware['currentStore'] = require('../middleware/currentStore.js')
middleware['currentStore'] = middleware['currentStore'].default || middleware['currentStore']

middleware['currentUser'] = require('../middleware/currentUser.js')
middleware['currentUser'] = middleware['currentUser'].default || middleware['currentUser']

middleware['ga4ViewTracking'] = require('../middleware/ga4ViewTracking.js')
middleware['ga4ViewTracking'] = middleware['ga4ViewTracking'].default || middleware['ga4ViewTracking']

export default middleware
