export default (http) => ({
  getStores () {
    return http.get('/stores')
  },
  getStore (data) {
    return http.get('/stores/search', data)
  },
  getInfoPages (data) {
    return http.get('/info-pages', data)
  },
  register (data) {
    return http.post('/register', data)
  },
  verifyRegister (data) {
    return http.get('/verify-register', data)
  },
  getCustomer (data) {
    return http.get('/customers', data)
  },
  updateCustomer (data) {
    return http.put('/customers', data)
  },
  createCustomer (data) {
    return http.post('/customers', data)
  },
  requestDelete (data) {
    return http.get('customers/request-delete', data)
  },
  verifylink (data) {
    return http.get('/verifylink', data)
  },
  deleteAccount (data) {
    return http.delete('/customers', data)
  },
  logout (data) {
    return http.post('/logout', data)
  },
  login (data) {
    return http.post('/login', data)
  },
  loginFacebook () {
    return http.get('/login/facebook')
  },
  handleLoginProvider (provider, token) {
    return http.get('/login/' + provider + '/callback?token=' + token)
  },
  orders (data) {
    return http.get('/orders', data)
  },
  getPaymentToken(data = {}) {
    return http.get('/orders/paymentToken', Object.assign(data , { requestToken: true, 'payment_method': 'card' }))
  },
  getCustomerAddresses (data) {
    return http.get('/customers/addresses', data)
  },
  getPostcodeAddresses (data) {
    return http.get('/customers/addresses', data)
  },
  addUpdateAddress (data) {
    return http.put('/customers/update-address', data)
  },
  deleteAddress (data) {
    return http.delete('/customers/delete-address', data)
  },
  getServerTime (data) {
    return http.get('/server-time', data)
  },
  forgotPassword (data) {
    return http.post('/forgot-password', data)
  },
  resetPassword (data) {
    return http.post('/reset-password', data)
  },
  sendOrder (data) {
    return http.post('/orders' + (window.debug ? '?debug=true' : ''), data)
  },
  thirdPartyOrder (data) {
    return http.post('/orders/third-party', data)
  },
  deleteThirdPartyOrder (data) {
    return http.delete('/orders/third-party', data)
  },
  rateOrder (data) {
    return http.post('/rate-order', data)
  },
  updateOrder (id, data) {
    return http.put(`/orders/${id}`, data)
  },
  getOrders(data) {
    return http.get('/orders/get', data)
  },
  getStaff(data) {
    return http.get(`/staff/users`, data)
  },
  rateCustomer(data) {
    return http.patch(`/customers/rate-customer`, data)
  },
  getVoucher (data) {
    return http.get('/vouchers/getVoucher', data)
  },
  getLoyalty (data) {
    return http.get('/customers/loyalty', data)
  },
  getNews (data) {
    return http.get('/news', data)
  },
  orderDetail(orderId) {
    return http.get(`/orders/${orderId}`)
  },
  findCustomer (data) {
    return http.get('/customers/find-customer', data)
  },
  sendDriverStatus(data) {
    return http.post('/drivers/driver-status', data)
  },
  getActiveOrdersForDelivery(staffId, data) {
    return http.get(`/drivers/${staffId}/orders-active`, data)
  },
  getHistoryOrdersForDelivery(staffId, data) {
    return http.get(`/drivers/${staffId}/orders-history`, data)
  },
  cashOff(data) {
    return http.put('/drivers/cash-off', data)
  },
  sendToPrinter(data) {
    return http.post('/web-printer', data)
  },
  zReport (data) {
    return http.get('/orders/zReport', data)
  },
  fixDealItems(dealId) {
    return http.get(`/orders/${dealId}/fix-deal-items`)
  },
  appVersion() {
    return http.get(`/app-min-version`)
  }
})
