// calculate order time

export default {
  isTimestrValid (timestr) {
    // Check if time string format is valid.
    if (!timestr) {
      return false
    }
    try {
    const timeArr = timestr.split(',')
    if (timeArr.length === 2) {
      const startTime = timeArr[0].trim().split(':')
      const endTime = timeArr[0].trim().split(':')
      if (startTime.length === 2 && endTime.length === 2) {
        try {
          return parseInt(startTime[0]) >= 0 && parseInt(startTime[0]) <= 23 && parseInt(startTime[1]) >= 0 && parseInt(startTime[1]) <= 59 && parseInt(endTime[0]) >= 0 && parseInt(endTime[0]) <= 23 && parseInt(endTime[1]) >= 0 && parseInt(endTime[1]) <= 59
        } catch (error) {
          return false
        }
      }
    }
    return false
    } catch (error) {
      return false
    }
  },
  getTimeRange (timeStr, momentObj) {
    // This functions converts the time string into moment object.
    if (!this.isTimestrValid(timeStr) || !momentObj.isValid()) {
      return null
    }
    const timeArr = timeStr.split(',').map(n => n.trim())
    const storeDayRange = {
      startTime: momentObj.clone().set({ hours: timeArr[0].split(':')[0], minutes: timeArr[0].split(':')[1], seconds: 0 }),
      endTime: momentObj.clone().set({ hours: timeArr[1].split(':')[0], minutes: timeArr[1].split(':')[1], seconds: 0 })
    }
    if (storeDayRange.startTime.isAfter(storeDayRange.endTime)) {
      storeDayRange.endTime.add(1, 'days')
    }
    return storeDayRange
  },
  storeYesterdayTodayTimeRange (storeInfo, momentObj) {
    const range = {}
    if (storeInfo && storeInfo.schedule) {
      const yesterdayMoment = momentObj.clone().subtract(1, 'day')

      let yesterday = storeInfo.schedule[yesterdayMoment.format('dddd').toLowerCase()]
      if (this.isTimestrValid(yesterday)) {
        yesterday = yesterday.split(',').map(n => n.trim())
        const yesterdayRange = {
          startTime: yesterdayMoment.clone().set({ hours: yesterday[0].split(':')[0], minutes: yesterday[0].split(':')[1], seconds: 0 }),
          endTime: yesterdayMoment.clone().set({ hours: yesterday[1].split(':')[0], minutes: yesterday[1].split(':')[1], seconds: 0 })
        }
        if (yesterdayRange.startTime.isAfter(yesterdayRange.endTime)) {
          yesterdayRange.endTime.add(1, 'days')
        }
        range.yesterday = yesterdayRange
      }

      let today = storeInfo.schedule[momentObj.clone().format('dddd').toLowerCase()]
      if (this.isTimestrValid(today)) {
        today = today.split(',').map(n => n.trim())
        const todayRange = {
          startTime: momentObj.clone().clone().set({ hours: today[0].split(':')[0], minutes: today[0].split(':')[1], seconds: 0 }),
          endTime: momentObj.clone().clone().set({ hours: today[1].split(':')[0], minutes: today[1].split(':')[1], seconds: 0 })
        }
        if (todayRange.startTime.isAfter(todayRange.endTime)) {
          todayRange.endTime.add(1, 'days')
        }
        range.today = todayRange
      }
    }
    return range
  },
  isStoreClosed (storeInfo, momentObj) {
    if (!storeInfo || !momentObj.isValid()) {
      // Could not check because of invalid parameters.
      return null
    }
    const storeDayRange = this.getTimeRange(storeInfo.schedule[momentObj.format('dddd').toLowerCase()], momentObj)
    if (storeDayRange) {
      return !(momentObj.isAfter(storeDayRange.startTime) && momentObj.isBefore(storeDayRange.endTime))
    }
    return true
  }
}
  