//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters } from 'vuex'
import pluralize from 'pluralize'
import moment from 'moment'

export default {
  name: 'Banner',
  data () {
    return {
      slideNumber: 0
    }
  },
  computed: {
    ...mapGetters(['banners', 'currentStore']),
    slides () {
      if (this.banners.length) {
        return this.banners.filter((banner) => {
          if (this.$route.name === 'index' || this.$route.name === 'all') {
            return banner.positions.includes('home')
          } else {
            return Object.keys(this.$route.params).length ? banner.positions.includes(pluralize.singular(this.$route.params.pathMatch)) : false
          }
        }).map((banner) => {
          return banner.slides.filter((slide) => {
            return slide.days.length ? slide.days.includes(moment().format('dddd').toLowerCase()) : true
          }).map((slide) => {
            return { path: slide.image, link: slide.link, description: banner.description }
          })
        }).flat()
      }
      return []
    }
  }
}
