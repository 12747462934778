//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'

import cartItemAdded from '../../../common/mixins/cart/cartItemAdded'
import sidesImg from '~/assets/images/sides.jpg'
import drinksImg from '~/assets/images/drinks.jpg'
import dessertsImg from '~/assets/images/desserts.jpg'
import pizzaImg from '~/assets/images/pizzas.jpg'

export default {
  name: 'CartItemAddedModal',
  mixins: [cartItemAdded],
  computed: {
    categorySuggestions () {
      let suggestions = false
      if (this.addedProduct) {
        switch (this.addedProduct.product_type) {
          case 'pizza':
            suggestions = [
              { name: 'sides', img: sidesImg },
              { name: 'drinks', img: drinksImg },
              { name: 'desserts', img: dessertsImg }
            ]
            break
          case 'side':
            suggestions = [
              { name: 'pizzas', img: pizzaImg },
              { name: 'drinks', img: drinksImg },
              { name: 'desserts', img: dessertsImg }
            ]
            break
          case 'drink':
            suggestions = [
              { name: 'pizzas', img: pizzaImg },
              { name: 'sides', img: sidesImg },
              { name: 'desserts', img: dessertsImg }
            ]
            break
          case 'dessert':
            suggestions = [
              { name: 'pizzas', img: pizzaImg },
              { name: 'sides', img: sidesImg },
              { name: 'drinks', img: drinksImg }
            ]
            break
          case 'dip':
            suggestions = [
              { name: 'pizzas', img: pizzaImg },
              { name: 'sides', img: sidesImg },
              { name: 'drinks', img: drinksImg }
            ]
            break
        }
      }
      return suggestions
    }
  },
  mounted () {
    const modal = this.$refs.productAddedModal
    this.$root.$on('productAddedToCart', (data) => {
      this.addedProduct = data.product
      if (this.addedProduct.items && this.addedProduct.items.length > 0) {
        this.addonSelection = []
        this.addedProduct.items.forEach(() => {
          this.addonSelection.push(null)
        })
        this.extraItems = true
      } else {
        this.addonSelection = []
        this.extraItems = false
      }
      modal.show()
    })
  },
  methods: {
    ...mapActions(['addToCart']),
    addProducts () {
      let product = JSON.parse(JSON.stringify(this.addedProduct))
      this.extraItems = false
      const products = this.addonSelection.filter(item => item !== null)
      if (products.length) {
        product = Object.assign(product, { products })
      }
      this.addToCart(product)
      this.$root.$emit('productAddedToCart', { product: Object.assign(product, { items: [] }) })
    },
    goToCheckout () {
      this.$refs.productAddedModal.hide()
      this.$router.push('/order/cart')
    },
    gotToCategory (category) {
      this.$router.push('/menu/' + category)
      this.$refs.productAddedModal.hide()
    }
  }
}
