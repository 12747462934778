import cart from './cart'
import stores from './stores'
import user from './user'
import data from  './data'
import info from './info'
import banners from './banners'
import products from './products'
import orderHistory from './orderHistory'
import nuxt from './nuxt'
import address from './address'
import time from './time'
import voucher from './voucher'
import redirect from './redirect'
import orders from './orders'

export default {
  nuxt,
  cart,
  stores,
  data,
  info,
  banners,
  products,
  user,
  orderHistory,
  address,
  time,
  voucher,
  redirect,
  orders
}