import slug from 'slug'

export default {
  methods: {
    redirectAfterStoreSearch () {
      if (['menu-product-all', 'menu-deal-all'].includes(this.$route.name)) {
        this.$router.go()
      } else {
        this.$router.push({ path: '/' })
      }
    },
    redirectAfterStoreSet (store) {
      if (this.$route.query && this.$route.query.from) {
        this.$router.push({ path: this.$route.query.from })
      } else {
        this.$router.push({ path: '/' + store.nickname })
      }
    },
    redirectAfterLogin () {
      return this.$root.$router.push({ name: 'index' })
    },
    redirectAfterLogout () {
      this.$router.push({ name: 'auth' })
    },
    redirectToLogin () {
      this.$router.push({ name: 'auth' })
    },
    redirectToProduct (product) {
      this.$router.push({ path: '/menu/product/' + slug(product.name) })
    },
    redirectToDeal (product) {
      this.$router.push({ path: '/menu/deal/' + slug(product.name) })
    },
    redirectAfterCustomization () {
      this.$router.push({ path: '/order/cart' })
    },
    redirectToDealCustomization (product) {
      this.$router.push({ path: '/menu/deal/' + slug(product.name) })
    },
    redirectToPizzaCustomization (product) {
      this.$router.push({ path: '/menu/product/' + slug(product.name) })
    },
    redirectToHalfNHalfCustomization () {
      this.$router.push({ path: '/menu/product/halfnhalf' })
    },
    redirectToThankyou () {
      this.$router.push({ path: '/order/thankyou' })
    },
    redirectToTracker () {
      this.$router.push({ path: '/order/tracker' })
    },
    redirectToLogistic () {
      this.$router.push({ path: '/order/logistic' })
    },
    redirectToCheckout () {
      this.$router.push({ path: '/order/checkout' })
    },
    redirectToPayment() {
      this.$router.push({ path: '/order/payment'})
    },
    redirectToHome () {
      this.$router.push({ path: '/'})
    },
    redirectToLoyalty () {
      this.$router.push({ path: '/loyalty'})
    },
    redirectToProfile () {
      this.$router.push({ name: 'profile' })
    }
  }
}
