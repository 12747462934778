// vuex store products
import slug from 'slug'

import * as ingredientUtils from '../utils/ingredients'

const state = () => ({
  products: {},
  ingredientGroups: [],
  freePizzaRewardDeal: null

})

const getters = {
  products (state) {
    return state.products
  },
  ingredientGroups (state) {
    return state.ingredientGroups
  },
  bases (state, getters) {
    return ingredientUtils.getBases(getters.ingredientGroups)
  },
  toppings (state, getters) {
    return ingredientUtils.getToppings(getters.ingredientGroups)
  },
  toppingList (state, getters) {
    return getters.toppings.map(group => group.ingredients).flat()
  },
  createYourOwnProduct (state, getters) {
    if (getters.products.pizza) {
      return getters.products.pizza.items.filter(pizza => slug(pizza.name) === 'create-your-own')[0]
    }
    return null
  },
  freePizzaRewardDeal (state) {
    return state.freePizzaRewardDeal
  }
}

const mutations = {
  setProducts (state, data) {
    if (typeof data === 'object') {
      state.products = data
    }
  },
  setIngredientGroups (state, data) {
    if (Array.isArray(data)) {
      state.ingredientGroups = data
    }
  },
  setFreePizzaRewardDeal (state, data) {
    state.freePizzaRewardDeal = data
  }
}

export default {
  state,
  getters,
  mutations
}