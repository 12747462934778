import moment from 'moment'
import { expandCart } from '../utils/orderProcessor'
import { containsCookable } from '../utils/kitchenHelper'

const state = () => ({
  orders: [],
  currentOrder: null,
  orderForDriver: null,
  archives: {
    orders: [],
    links: null,
    meta: null
  },
  thirdParty: {
    orders: [],
    links: null,
    meta: null
  },
  zReport: null
})

const mutations = {
  setArchives (state, data) {
    state.archives.orders = data.data
    state.archives.links = data.links
    state.archives.meta = data.meta
  },
  setOrders (state, orders) {
    state.orders = orders
  },
  setOrder (state, order) {
    const index = state.orders.findIndex(item => item.id === order.id)
    if (index !== -1) {
      state.orders[index] = Object.assign(state.orders[index], order)
    } else {
      state.orders.push(order)
    }
  },
  removeOrder (state, order) {
    state.orders = state.orders.filter(item => item.id !== order.id)
  },
  setCurrentOrder (state, order) {
    state.currentOrder = order
  },
  setOrderForDriver (state, order) {
    state.orderForDriver = order
  },
  setThirdParty (state, data) {
    state.thirdParty.orders = data.data
    state.thirdParty.links = data.links
    state.thirdParty.meta = data.meta
  },
  setZReport (state, data) {
    state.zReport = data
  }
}

const actions = {
  updateOrder (context, data) {
    return this.$api.updateOrder(data.id, data.data).then((resp) => {
      return resp.data
    }).catch((e) => {
      if (e.response === undefined) {
        return false
      }
      if (e.response.status === 401) {
        // this.$router.push('/login')
      }
      return e.response.data
    })
  },
  fetchOrders (context) {
    if (context.getters.currentStore) {
      return this.$api.getOrders({
        store_id: context.getters.currentStore.id,
        endDate: moment().utc().subtract(18, 'hours').format('YYYY-MM-DD')
      }).then((resp) => {
        let orders = resp.data.data
        orders = orders.map((order) => {
          // Expanding json cart to show order items on kitchen screen
          if (order.order_data) {
            try {
              order.cart = expandCart(JSON.parse(order.order_data), context.getters.products, context.getters.ingredientGroups, true, context.getters.freePizzaRewardDeal)
            } catch (error) {
              console.log(error)
            }
          } else {
            order.cart = []
          }
          return order
        })
        context.commit('setOrders', orders)
        return orders
      })
    }
  },
  getArchives (context, data) {
    return this.$api.orders(data).then((resp) => {
      context.commit('setArchives', resp.data.data)
      return resp.data
    }).catch((e) => {
      if (e.response === undefined) {
        return false
      }
      if (e.response.status === 401) {
        // this.$router.push('/login')
      }
      return e.response.data
    })
  },
  getThirdParty (context, data) {
    if (data.source === null) {
      data.source = 'all'
    }

    return this.$api.orders(data).then((resp) => {
      context.commit('setThirdParty', resp.data.data)
      return resp.data
    }).catch((e) => {
      if (e.response === undefined) {
        return false
      }
      if (e.response.status === 401) {
        // this.$router.push('/login')
      }
      return e.response.data
    })
  },
  deleteThirdParty (context, data) {
    return this.$api.deleteThirdPartyOrder(data).then((resp) => {
      return resp.data
    }).catch((e) => {
      if (e.response === undefined) {
        return false
      }
      if (e.response.status === 401) {
        // this.$router.push('/login')
      }
      return e.response.data
    })
  },
  driverCashOff (context, data) {
    return this.$api.cashOff(data).then((resp) => {
      return resp.data
    }).catch((e) => {
      if (e.response === undefined) {
        return false
      }
      if (e.response.status === 401) {
        this.$router.push('/login')
      }
      return e.response.data
    })
  },
  getZReport (context, data) {
    return this.$api.zReport(data).then((resp) => {
      context.commit('setZReport', resp.data.data)
      return resp.data
    }).catch((e) => {
      if (e.response === undefined) {
        return false
      }
      if (e.response.status === 401) {
        this.$router.push('/login')
      }
      return e.response.data
    })
  },
}

const getters = {
  orders (state, getters) {
    if (getters.currentStore) {
      return state.orders
    }
    return []
  },
  activeOrders (state, getters) {
    if (getters.currentTime) {
      return getters.orders.filter((order) => {
        return ['paid', 'pending', 'accepted', 'cooking', 'cooked', 'to_be_delivered', 'picked_up_for_delivery', 'left_the_shop'].includes(order.status)
        && (moment(order.requested_at, 'X').isSameOrBefore(moment().endOf('day')) || moment(order.requested_at, 'X').isSameOrBefore(moment().add(2, 'hours')))
      })
    }
    return []
  },
  zReportOrders (state, getters) {
    return getters.orders.filter((order) => {
      return ['delivered', 'out_of_coverage', 'double_order', 'shop_closing', 'bad_weather', 'refunded', 'canceled'].includes(order.status)
      && moment(order.requested_at, 'X').isAfter(moment().subtract(18, 'hours'))
    })
  },
  zReport (state) {
    return state.zReport
  },
  kitchenOrders (state, getters) {
    return getters.orders.filter((order) => {
      return ['accepted', 'cooking'].includes(order.status)
      && (moment(order.estimated_at).isSameOrBefore(moment().endOf('day')) || moment(order.estimated_at).isSameOrBefore(moment().add(2, 'hours')))
      && containsCookable(order)
    })
  },
  archives (state) {
    return state.archives
  },
  currentOrder: state => state.currentOrder,
  orderForDriver: state => state.orderForDriver,
  thirdParty: state => state.thirdParty
}

export default {
  state,
  getters,
  actions,
  mutations
}
