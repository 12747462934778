// Nuxt server init module

const actions = {
  async nuxtServerInit (context, req) {
    await context.dispatch('getStores')

    try {
      const store = context.getters.stores.filter(store => store.nickname.toLowerCase() === req.route.path.replace('/', '').toLowerCase())
      const cookieData = this.$cookie ? JSON.parse(this.$cookie) : false
      if (cookieData) {
        if (store.length === 0) {
          await context.dispatch('setCurrentStore', (cookieData.data.currentStore ? { store_id: cookieData.data.currentStore.id } : null))
        } else {
          // we need to modify also the cookie
          await context.dispatch('setCurrentStore', ({ store_id: store[0].id }))
          cookieData.data.currentStore = context.getters.currentStore
          req.res.setHeader('Set-Cookie', [`${this.$cookieKey}=${JSON.stringify(cookieData)}`]) // setup the cookie from the serverside
        }
      } else if (store.length !== 0) {
        await context.dispatch('setCurrentStore', { store_id: store[0].id })
      } else {
        await context.dispatch('setCurrentStore', null)
      }
    } catch (e) {
      console.log('e', e)
    }
  }
}

export default {
  actions
}
